import { Card, Divider, IconButton, List, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { Add, ArrowForwardIos, EmojiObjectsOutlined, SummarizeOutlined } from "@mui/icons-material"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"
import { useContext, useEffect, useState } from "react"
import { smartTime, startOfDay } from "forge/core/utilities"
import KnowledgeCard from "../KnowledgeCard"
import { Knowledge } from "forge/knowledge/schemas/knowledge"
import { createUseStyles } from "react-jss"
import CardTitle from "../CardTitle"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"

const styles = createUseStyles({
  lightGray: { color: "darkgray" }
})

function ContactRecallCard({
  contact,
  handleOpenDrawer,
  handleOpenDetailDrawer
}: {
  contact: any
  handleOpenDrawer: () => void
  handleOpenDetailDrawer: (knowledge: any) => void
}) {
  // UI
  const classes = styles()

  // Context
  const { knowledge, knowledgeSummary } = useContext(KnowledgeContext)

  // State
  const [sections, setSections] = useState([])

  useEffect(() => {
    setSections(orderKnowledge(knowledge))
  }, [knowledge])

  function orderKnowledge(knowledge: Knowledge[]): any[] {
    const groupedKnowledge: { [key: string]: any[] } = {}
    let currentDay: string | undefined

    for (const currentKnowledge of knowledge) {
      const currentDayTmp: Date = startOfDay(currentKnowledge.createdAt)
      if (currentDay !== currentDayTmp.toISOString()) {
        currentDay = currentDayTmp.toISOString()
      }

      const knowledgeGroup: any[] = groupedKnowledge[currentDay] ?? []
      knowledgeGroup.push(currentKnowledge)
      groupedKnowledge[currentDay] = knowledgeGroup
    }

    // Used for Ascending Chronological Order for same day Knowledge
    for (const key of Object.keys(groupedKnowledge)) {
      groupedKnowledge[key] = groupedKnowledge[key].reverse()
    }

    let sections = []
    for (const [key, value] of Object.entries(groupedKnowledge)) {
      let keyDate = new Date(key)
      sections.push({
        items: value,
        header:
          Math.abs(keyDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24) < 29
            ? smartTime(keyDate)
            : new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric" }).format(keyDate)
      })
    }

    return sections
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Recall" tooltipInfo={CARDS_TOOLTIPS.RECALL} />
        <MDBox display="flex" alignItems="center" sx={{ justifyContent: "space-between" }}>
          {sections && sections.length > 0 && (
            <MDBox
              width="3rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              style={{ cursor: "pointer", background: "cornflowerblue" }}
              onClick={handleOpenDrawer}
            >
              <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
                <EmojiObjectsOutlined style={{ color: "white" }} />
                <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-10px", right: "-4px" }} />
              </IconButton>
            </MDBox>
          )}
        </MDBox>
        <Divider style={{ opacity: 1 }} />

        {knowledgeSummary && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1}>
              <SummarizeOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                {`${contact.firstName}'s Personal Summary`}
              </MDTypography>
            </MDBox>
            <MDTypography variant="body2" color="text" textAlign="justify" style={{ whiteSpace: "pre-line" }}>
              {knowledgeSummary}
            </MDTypography>
            <Divider variant="middle" />
          </MDBox>
        )}

        {sections && sections.length > 0 ? (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper"
            }}
          >
            {sections.map(e => (
              <MDBox>
                <MDTypography fontSize="small" align="center" style={{ color: "gray", marginTop: 1.5, marginBottom: 1.5 }}>
                  {e.header}
                </MDTypography>
                {e.items.map((individualKnowledge: any) => {
                  return <KnowledgeCard contact={contact} knowledge={individualKnowledge} handleOpenDetailDrawer={handleOpenDetailDrawer} />
                })}
              </MDBox>
            ))}
          </List>
        ) : (
          <Stack mt={5} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
            <MDTypography
              variant="body2"
              align="center"
              style={{ color: "darkgray" }}
            >{`What have you learned from ${contact?.firstName}?`}</MDTypography>
            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
            <MDBox
              width="3rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              style={{ cursor: "pointer", background: "cornflowerblue" }}
              onClick={handleOpenDrawer}
            >
              <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
                <EmojiObjectsOutlined style={{ color: "white" }} />
                <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-10px", right: "-4px" }} />
              </IconButton>
            </MDBox>
          </Stack>
        )}
      </MDBox>
    </Card>
  )
}

export default ContactRecallCard
