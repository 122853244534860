// MUI
import { Avatar, Backdrop, Box, Drawer, IconButton, Menu, MenuItem, Stack, Tooltip, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

import theme from "assets/theme"
import TeamCard from "../../../teams/components/TeamCard"
import { Close, Delete, KeyboardArrowDown } from "@mui/icons-material"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { capitalize } from "lodash"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { useContext, useState } from "react"
import { MembersContext } from "../../services/MembersContext"
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import { Can } from "forge/organization/rbac/CanContext"
import { AuthContext } from "context"

function MemberDrawer({
  openDrawer,
  handleCloseDrawer,
  member
}: {
  openDrawer: boolean
  handleCloseDrawer: () => void
  member?: ForgeOrganizationMember
}) {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { changeRole, removeMember } = useContext(MembersContext)
  const { user } = getCurrentUser()

  // State
  const [loading, setLoading] = useState(false)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const handleOpenDialogDelete = () => setOpenDialogDelete(true)
  const handleCloseDialogDelete = async (result: boolean) => {
    setOpenDialogDelete(false)
    if (result) {
      setLoading(true)
      await removeMember(member.ref.id, member)
      setLoading(false)
      handleCloseDrawer()
    }
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = async (newRole: string) => {
    setAnchorEl(null)

    if (newRole) {
      member.role = newRole
      changeRole(member.ref.id, member)
    }
  }
  const isActive = member?.status === "active"

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      <Backdrop sx={{ background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "absolute" }} open={loading}>
        <Stack direction="column" spacing={1}>
          <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
          <MDTypography variant="h6" textAlign="center">
            Removing member...
          </MDTypography>
        </Stack>
      </Backdrop>

      <ConfirmationDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        title="Are you sure you want to remove this member from your organization?"
        description=""
        denyVariant="outlined"
        confirmText="Remove"
      />
      {/* Header */}
      <div style={{ backgroundColor: "black", padding: 24 }}>
        {isActive && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(undefined)}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem onClick={() => handleClose("admin")}>Administrator</MenuItem>
            <MenuItem onClick={() => handleClose("manager")}>Manager</MenuItem>
            <MenuItem onClick={() => handleClose("representative")}>Representative</MenuItem>
          </Menu>
        )}
        <Stack direction="row" justifyContent="space-between">
          <MDBox display="flex" alignItems="center">
            <Avatar alt={member?.name} src={member?.profilePictureUrl}>
              {member?.name && member?.name[0]}
            </Avatar>
            <MDTypography variant="body2" ml={2} style={{ color: "white" }}>
              {member?.name}
            </MDTypography>
          </MDBox>
          {user.uid !== member?.ref?.id && (
            <IconButton onClick={() => handleOpenDialogDelete()}>
              <Delete style={{ color: "white" }} />
            </IconButton>
          )}
        </Stack>
        <Box height={12} />
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="body2" fontWeight="bold" style={{ color: "white" }}>
            Added
          </MDTypography>
          <Box width={4} />
          <MDTypography variant="body2" style={{ color: "white" }}>
            {member?.joinedAtLocalized}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="body2" fontWeight="bold" style={{ color: "white" }}>
            Role
          </MDTypography>
          <Box width={4} />
          <Can I="edit-role" on="members" passThrough>
            {allowed => (
              <Tooltip
                title={
                  isActive && allowed ? undefined : allowed ? "Role can only be edited for active members." : "You have insufficient permissions."
                }
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignContent="center"
                  alignItems="center"
                  onClick={(e: any) => isActive && allowed && handleClick(e)}
                  style={{ cursor: "pointer" }}
                >
                  <MDTypography variant="body2" style={{ color: isActive && allowed ? "white" : "gray" }}>
                    {capitalize(member?.role)}
                  </MDTypography>
                  <KeyboardArrowDown style={{ color: isActive && allowed ? "white" : "gray" }} />
                </Stack>
              </Tooltip>
            )}
          </Can>
        </MDBox>
      </div>

      {/* Body */}
      <MDBox mx={3} mt={2}>
        {member?.teams?.map((team: any) => (
          <TeamCard team={team} subtitle={capitalize(member.role)} actions={<Close />} />
        ))}
      </MDBox>
    </Drawer>
  )
}

// Setting default values for the props of MemberDrawer
MemberDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default MemberDrawer
