import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormField from "layouts/pages/account/components/FormField"
import { useContext, useState } from "react"
import { Backdrop, Stack } from "@mui/material"
import MDButton from "components/MDButton"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { ForgeOrganization } from "forge/organization/types/organization"

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import { TeamsContext } from "../../services/TeamsContext"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { AuthContext } from "context"
import { doc } from "firebase/firestore"
import { firestoreDb } from "firebase.init"

export default function CreateTeamDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { createTeam } = useContext(TeamsContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()

  const onSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    const organization = new ForgeOrganizationTeam({
      name,
      description
    })
    await createTeam(organization)

    setLoading(false)
    internalHandleClose()
  }

  const internalHandleClose = () => {
    setName(undefined)
    setDescription(undefined)
    setLoading(false)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={internalHandleClose} fullWidth>
      <Backdrop sx={{ background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "absolute" }} open={loading}>
        <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
      </Backdrop>
      <DialogTitle>Create Team</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <FormField
            label="Name"
            placeholder="Team Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value)
            }}
          />
          <FormField
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(e: any) => {
              setDescription(e.target.value)
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" autoFocus onClick={internalHandleClose} color={"dark"}>
          Cancel
        </MDButton>
        <MDButton disabled={!name} onClick={onSubmit} color={"dark"}>
          Create
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}
