import { DndContext, DragEndEvent, useSensor, useSensors } from "@dnd-kit/core"
import { Autocomplete, Avatar, Box, createFilterOptions, Divider, Stack, TextField } from "@mui/material"
import MDBox from "components/MDBox"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { Droppable } from "./droppable"
import { MailOutlineRounded, PersonAdd, PersonSearchOutlined } from "@mui/icons-material"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { ContactsContext } from "context"
import { AttendeeType, ForgeAttendee, InvitationStatus } from "types/calendar/forge-attendee"
import { createUseStyles } from "react-jss"
import { DraggableChip } from "./draggableChip"
import { MouseSensor, TouchSensor } from "./draggable"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import CreateContactDrawer from "forge/people/contacts/components/CreateContactDrawer"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formColor: { alignSelf: "center", height: "1.5em", width: "1.5em" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    }
  },
  accordionRoot: {
    "&:before": {
      display: "none"
    }
  }
})

export function AttendeesSelector({
  attendees = [],
  setAttendees,
  attendeesResearch = [],
  setAttendeesResearch
}: {
  attendees: ForgeAttendee[]
  setAttendees?: Dispatch<SetStateAction<ForgeAttendee[]>>
  attendeesResearch: ForgeAttendee[]
  setAttendeesResearch?: Dispatch<SetStateAction<ForgeAttendee[]>>
}) {
  // UI
  const classes = styles()
  const customSensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // Context
  const { contacts } = useContext(ContactsContext)
  const [initialCreateValue, setInitialCreateValue] = useState<string>("")
  const [inviteSearchText, setInviteSearchText] = useState<string>("")
  const [inviteInputLoading, setInviteInputLoading] = useState<boolean>(false)
  const [researchSearchText, setResearchSearchText] = useState<string>("")
  const [researchInputLoading, setResearchInputLoading] = useState<boolean>(false)
  const [invokedFrom, setInvokedFrom] = useState<string>(null)
  const [emailForInvite, setEmailForInvite] = useState<string>(null)
  const [openCreateContactDrawer, setOpenCreateContactDrawer] = useState(false)

  const handleOpenCreateDrawer = (isResearch = false) => {
    setInvokedFrom(isResearch ? "research" : "invite")
    setInitialCreateValue(isResearch ? researchSearchText : inviteSearchText)
    setOpenCreateContactDrawer(true)
  }
  const handleCloseCreateDrawer = () => setOpenCreateContactDrawer(false)

  // State
  const filterContacts = () => {
    let options: ForgeAttendee[] = []
    let contactsWithEmails = contacts.filter((contact: any) => contact.emailStrings?.length > 0)
    for (const contact of contactsWithEmails) {
      for (const email of contact.emailStrings) {
        if (email && email.trim()) {
          options.push(
            new ForgeAttendee({
              contact: contact,
              type: AttendeeType.normal,
              name: contact.name,
              emailAddress: email.trim(),
              status: InvitationStatus.none
            })
          )
        }
      }
    }

    if (emailForInvite) {
      const attendee = contacts.find(contact => contact.id === emailForInvite)
      if (invokedFrom === "research") {
        onResearchAttendeeAdded(null, [...attendeesResearch, attendee])
        setResearchSearchText("")
        setResearchInputLoading(false)
      } else {
        onAttendeeAdded(null, [...attendees, attendee])
        setInviteSearchText("")
        setInviteInputLoading(false)
      }
      setEmailForInvite(null)
    }

    return options
  }
  const [attendeesOptions, setAttendeesOptions] = useState<any[]>(filterContacts)
  useEffect(() => {
    setAttendeesOptions(filterContacts())
  }, [contacts])

  const filterOptions = createFilterOptions({
    matchFrom: "any", // Can be 'anywhere' or 'start'
    stringify: (option: any) => `${option.name} ${option.emailAddress}`
  })

  const onAttendeeAdded = (_: any, newValue: any[], skipResearchAssignment = false) => {
    let newResearchAttendees: any[] = []
    let newAttendees = newValue
      .map(value => {
        if (value instanceof ForgeAttendee) {
          return value
        }

        if (value.emailStrings.length <= 0) {
          newResearchAttendees.push(value)
          return undefined
        }

        return new ForgeAttendee({
          contact: value,
          type: AttendeeType.normal,
          name: value.name,
          emailAddress: value.emailStrings[0],
          status: InvitationStatus.none
        })
      })
      .filter(e => e)

    if (!skipResearchAssignment) {
      onResearchAttendeeAdded(null, [...attendeesResearch, ...newResearchAttendees])
    }
    setInviteSearchText("")
    return setAttendees(newAttendees)
  }

  const onAttendeeRemoved = ({ attendeeRemovedId, attendeeRemoved }: { attendeeRemovedId?: string; attendeeRemoved?: any }) =>
    setAttendees(prevContacts => prevContacts.filter(attendee => attendee.contact.id !== (attendeeRemovedId ?? attendeeRemoved.contact.id)))

  const onResearchAttendeeAdded = (event: any, newValue: any[]) => {
    setAttendeesResearch(
      newValue.map(value => {
        if (value instanceof ForgeAttendee) {
          return value
        }

        return new ForgeAttendee({
          contact: value,
          type: AttendeeType.research,
          name: value.name,
          emailAddress: value.emailStrings?.length > 0 ? value.emailStrings[0] : "",
          status: InvitationStatus.none
        })
      })
    )
    setResearchSearchText("")
  }

  const onResearchAttendeeRemoved = ({ attendeeRemovedId, attendeeRemoved }: { attendeeRemovedId?: string; attendeeRemoved?: any }) =>
    setAttendeesResearch(prevContacts => prevContacts.filter(attendee => attendee.contact.id !== (attendeeRemovedId ?? attendeeRemoved.contact.id)))

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      if (over.id === "emailAttendees") {
        onResearchAttendeeRemoved({ attendeeRemovedId: active.data.current?.id })
        if (attendees.some(e => e.contact?.id === active.data.current?.id)) return
        onAttendeeAdded(undefined, [...attendees, active.data.current], true)
      } else if (over.id === "researchAttendees") {
        onAttendeeRemoved({ attendeeRemovedId: active.data.current?.id })
        if (attendeesResearch.some(e => e.contact?.id === active.data.current?.id)) return
        onResearchAttendeeAdded(undefined, [...attendeesResearch, active.data.current])
      }
    }
  }

  const handleContactCreation = (email: string) => {
    if (invokedFrom === "research") setResearchInputLoading(true)
    else setInviteInputLoading(true)
    setEmailForInvite(email)
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={customSensors}>
      <MDBox className={classes.formTile}>
        <CreateContactDrawer
          openDrawer={openCreateContactDrawer}
          handleCloseDrawer={handleCloseCreateDrawer}
          enableNavigation={false}
          initialEmail={invokedFrom === "research" ? "" : initialCreateValue}
          initialFirstName={invokedFrom === "research" ? initialCreateValue : ""}
          invokedFromInvite
          setEmailForInvite={handleContactCreation}
        />
        <MailOutlineRounded className={classes.formIcon} />

        <Autocomplete
          multiple
          filterSelectedOptions
          id="attendees-select"
          sx={{ width: 300 }}
          style={{ flex: 1 }}
          value={attendees}
          options={attendeesOptions}
          getOptionDisabled={option =>
            attendees.find(attendee => attendee.contact?.id === option.contact?.id) !== undefined ||
            attendeesResearch.find(attendee => attendee.contact?.id === option.contact?.id) !== undefined
          }
          autoHighlight
          getOptionLabel={option => option?.emailAddress ?? ""}
          filterOptions={filterOptions}
          renderTags={(value: ForgeAttendee[]) => {
            return (
              <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                {value.map((attendee, index) => (
                  <DraggableChip
                    key={index}
                    attendee={attendee}
                    onAttendeeRemoved={attendeeRemoved => onAttendeeRemoved({ attendeeRemoved: attendeeRemoved })}
                  />
                ))}
                <Avatar style={{ background: "cornflowerblue", marginTop: 4, width: "32px", height: "32px", cursor: "pointer" }}>
                  <PersonAdd fontSize="small" style={{ color: "white" }} />
                </Avatar>
              </Stack>
            )
          }}
          onChange={(event, newValue) => onAttendeeAdded(event, newValue)}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <MDBox mr={2}>
                <ForgeContactAvatar contact={option.contact} enableScoreBadge={false} />
              </MDBox>
              <Stack direction="column">
                {option.name}
                <MDTypography variant="body2" fontSize="small" style={{ color: "gray", lineHeight: "1rem" }}>
                  {option.emailAddress}
                </MDTypography>
              </Stack>
            </Box>
          )}
          inputValue={inviteSearchText}
          renderInput={params => (
            <Droppable id={"emailAttendees"}>
              <TextField
                {...params}
                className={classes.root}
                label="Invite people by email"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
                onChange={e => setInviteSearchText(e.target.value)}
              />
            </Droppable>
          )}
          noOptionsText={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              No Person found
              <MDButton variant="outlined" color="primary" onClick={() => handleOpenCreateDrawer()}>
                Add New Person
              </MDButton>
            </Box>
          }
          disabled={inviteInputLoading}
        />
        <ForgeLoading loading={inviteInputLoading} loadingType="small" style={{ paddingBottom: "8px" }} />
      </MDBox>
      <Divider />
      <MDBox className={classes.formTile}>
        <PersonSearchOutlined className={classes.formIcon} />
        <Autocomplete
          multiple
          filterSelectedOptions
          id="research-attendees-select"
          sx={{ width: 300 }}
          style={{ flex: 1 }}
          autoHighlight
          value={attendeesResearch}
          options={contacts}
          getOptionDisabled={option =>
            attendees.find(attendee => attendee.contact?.id === option.id) !== undefined ||
            attendeesResearch.find(attendee => attendee.contact?.id === option.id) !== undefined
          }
          getOptionLabel={option => option?.ref?.id ?? ""}
          filterOptions={filterOptions}
          renderTags={value => {
            return (
              <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                {value.map((attendee, index) => (
                  <DraggableChip
                    key={index}
                    attendee={attendee}
                    onAttendeeRemoved={attendeeRemoved => onResearchAttendeeRemoved({ attendeeRemoved: attendeeRemoved })}
                  />
                ))}
                <Avatar style={{ background: "cornflowerblue", marginTop: 4, width: "32px", height: "32px", cursor: "pointer" }}>
                  <PersonAdd fontSize="small" style={{ color: "white" }} />
                </Avatar>
              </Stack>
            )
          }}
          onChange={onResearchAttendeeAdded}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <MDBox mr={2}>
                <ForgeContactAvatar contact={option} enableScoreBadge={false} />
              </MDBox>
              {option.name}
            </Box>
          )}
          inputValue={researchSearchText}
          renderInput={params => (
            <Droppable id={"researchAttendees"}>
              <TextField
                {...params}
                className={classes.root}
                label="Add people for research only"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
                onChange={e => setResearchSearchText(e.target.value)}
              />
            </Droppable>
          )}
          noOptionsText={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              No Person found
              <MDButton variant="outlined" color="primary" onClick={() => handleOpenCreateDrawer(true)}>
                Add New Person
              </MDButton>
            </Box>
          }
          disabled={researchInputLoading}
        />
        <ForgeLoading loading={researchInputLoading} loadingType="small" style={{ paddingBottom: "8px" }} />
      </MDBox>
    </DndContext>
  )
}
