import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import HttpService from "forge/core/services/http.service"

class AuthApi {
  onUserAuthenticated = async (): Promise<any> => {
    const endpoint = `user/organization-membership`
    return await HttpService.get(endpoint, {})
  }

  authOutlookCalendar = async (code: string): Promise<any> => {
    const endpoint = `user/auth/microsoft?code=${code}`

    return await HttpService.get(endpoint)
  }

  getMicrosoftFirebaseUserToken = async (token: string): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }

    const callable = httpsCallable(functions, "auth-microsoftAuth")
    const response = await callable({ token })

    return response.data
  }
}

export default AuthApi
