/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react"

// @mui material components
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Material Dashboard 2 PRO React TS examples components
import PageLayout from "examples/LayoutContainers/PageLayout"

// Material Dashboard 2 PRO React context
import forgeBlackLogo from "assets/forge/svgs/forge_black.svg"
import appStoreDownload from "assets/forge/svgs/appstore_download.svg"
import { useMaterialUIController } from "context"
import Carousel from "react-material-ui-carousel"
import { useMediaQuery } from "@mui/material"
import Review from "./review"
import theme from "assets/theme"
import Showcase from "./showcase"

// Declaring props types for IllustrationLayout
interface Props {
  header?: ReactNode
  title?: string
  description?: string
  children: ReactNode
  illustration?: string
  content?: any
}

function IllustrationLayout({ header, title, description, illustration, children, content }: Props): JSX.Element {
  const pageContent = content?.pageContent ?? []
  const isSignIn = title === "Sign In"
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const lg = useMediaQuery(theme.breakpoints.down("lg"))
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) => (darkMode ? background.default : white.main)
        }}
      >
        <Grid item xs={12} lg={6}>
          {!isSignIn ? (
            <MDBox
              display={{ xs: "none", lg: "flex" }}
              style={{ backgroundSize: "70%", backgroundColor: "black" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{
                backgroundImage: `url(${illustration})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 24%"
              }}
            >
              {pageContent && (
                <Carousel interval={10000} sx={{ width: "100%", margin: "32px 12%" }} height="100%">
                  {pageContent?.map((testimonial: any, index: number) => {
                    return (
                      <Review
                        key={index}
                        review={testimonial.content}
                        image={testimonial?.imageLink ?? testimonial.image?.file?.url}
                        author={testimonial.author}
                        role={testimonial.authorRole}
                      />
                    )
                  })}
                </Carousel>
              )}
            </MDBox>
          ) : (
            <MDBox
              display={{ xs: "none", lg: "flex" }}
              style={{ backgroundSize: pageContent?.length > 0 ? "40%" : "70%", backgroundColor: "black" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{
                backgroundImage: `url(${illustration})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: `center${pageContent?.length > 0 ? " 15%" : ""}`
              }}
            >
              {pageContent && (
                <Carousel interval={10000} sx={{ width: "100%", margin: "32px 12%" }} height="100%">
                  {pageContent?.map((ele: ReactNode, index: number) => (
                    <Showcase key={index} page={ele} />
                  ))}
                </Carousel>
              )}
            </MDBox>
          )}
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
              background: "#E8F0FE",
              display: lg ? undefined : "none",
              width: "100vw",
              transform: `translateX(-${sm ? "4" : md ? "16.5" : "24.5"}%)`,
              padding: "16px 12%"
            }}
          >
            <MDTypography variant="h5" fontWeight="bold" textAlign="center" style={{ color: "black" }}>
              Download the Forge® App
            </MDTypography>
            <MDTypography variant="body2" textAlign="center">
              Enjoy seamless 2-way sync between desktop and mobile, so you can manage your relationships and tasks effortlessly, anytime, anywhere.
            </MDTypography>
            <img
              src={appStoreDownload}
              style={{ height: "56px", paddingTop: "8px", cursor: "pointer" }}
              alt="App Store Download"
              onClick={() => window.open("https://apps.apple.com/us/app/forge-business-relationships/id1582340845", "_blank")}
            />
          </MDBox>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <MDBox py={3} px={3} textAlign="center">
              <img src={forgeBlackLogo} style={{ height: "30px", display: lg ? undefined : "none" }} alt="Apple" />
              {!header ? (
                <>
                  <MDBox mb={1} textAlign="center">
                    <MDTypography variant="h4" fontWeight="bold" style={{ color: "black" }}>
                      {title}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {description}
                  </MDTypography>
                </>
              ) : (
                header
              )}
            </MDBox>
            <MDBox p={3}>{children}</MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: ""
}

export default IllustrationLayout
