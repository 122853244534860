import { Box, DialogContent, DialogContentText, Stack } from "@mui/material"
import questionBro from "assets/forge/images/question_bro.png"

function Relationship2Slide({}: {}) {
  return (
    <DialogContent>
      <DialogContentText align="center">{"Trust is built by"}</DialogContentText>
      <DialogContentText color="#3D79EE" fontWeight="bold" align="center">
        {"solving people’s problems"}
      </DialogContentText>
      <Box height={64} />
      <DialogContentText align="center">{"...but first people need to\nunderstand that"}</DialogContentText>
      <DialogContentText color="#3D79EE" fontWeight="bold" align="center">
        {"you\ngenuinely care"}
      </DialogContentText>
    </DialogContent>
  )
}

export default Relationship2Slide
