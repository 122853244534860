import { useContext, useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { AuthContext, ContactsContext } from "context"
import theme from "assets/theme"

// MUI
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Chip,
  createFilterOptions,
  Divider,
  Drawer,
  FormControl,
  Icon,
  Stack,
  TextField,
  useMediaQuery
} from "@mui/material"
import { Abc, CalendarMonth, EditNoteOutlined, OpenInNewRounded, PersonAdd } from "@mui/icons-material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Services
import { ForgeOpportunity } from "types/pipeline/opportunity"
import dayjs, { Dayjs } from "dayjs"
import { Call } from "types/pipeline/call"
import CallsApi from "../../services/api"

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer"
import SelectContact from "forge/core/components/SelectContact"
import { CalendarEvent } from "types/calendar/calendar-event"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { ContactChip } from "./contactChip"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    }
  }
})

function CreateCallDrawer({
  openDrawer,
  handleCloseDrawer,
  opportunity,
  call,
  event,
  contact
}: {
  openDrawer: boolean
  handleCloseDrawer: () => void
  opportunity?: ForgeOpportunity
  call?: Call
  event?: CalendarEvent
  contact?: any
}) {
  // UI
  const classes = styles()
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { contacts, getContacts, getPossibleUserContacts } = useContext(ContactsContext)

  // API
  let user = getCurrentUser()
  const callsApi = new CallsApi(user.userProfileData)

  // Options
  const [attendeeContacts, setAttendeeContacts] = useState<any[]>([])

  // State
  const drawerRef = useRef(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [subjectGeneratorActive, setSubjectGeneratorActive] = useState<boolean>(() => !(call instanceof Call))
  // const [contactSelectorActive, setContactSelectorActive] = useState(false);
  // const [selectedContacts, setSelectedContacts] = useState(new Set());
  const [name, setName] = useState("Conversation")
  const [description, setDescription] = useState("")
  const [closeDate, setCloseDate] = useState<Dayjs>(dayjs(new Date()))
  const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false)
  const handleOpenCommitmentDrawer = () => setOpenCreateCommitmentDrawer(true)
  const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false)
  // const handleOpenContactSelector = () => setContactSelectorActive(true);
  // const handleCloseContactSelector = () => setContactSelectorActive(false);

  useEffect(() => {
    let tmpContacts = []

    if (opportunity) {
      let contactIds = [...opportunity.clientContactsIds, ...opportunity.teamContactsIds]
      tmpContacts = getContacts(contactIds)
      setAttendeeContacts(tmpContacts)
      setName(`${opportunity.properties?.name} Call`)
    }

    if (event) {
      setAttendeeContacts(event.attendees.map(attendee => attendee.contact).filter(e => e))
      setName(`${event.name} Notes`)
      setCloseDate(dayjs(event.startDate.toDate()))
    }

    if (contact) {
      console.log("setAttendeeContacts", contact)
      setAttendeeContacts([contact])
    }

    if (call) {
      setAttendeeContacts(call.contacts)
      setName(call.subject)
      setDescription(call.details)
      setCloseDate(dayjs(call.date))
      setSubjectGeneratorActive(false)
    }

    if (opportunity && !call) {
      const possibleUserContacts = getPossibleUserContacts()
      let selectedOptions = new Set()

      for (const possibleContact of possibleUserContacts) {
        let userContact = tmpContacts.find(contact => contact.id === possibleContact.id)
        if (userContact) {
          selectedOptions.add(userContact)
        }
      }

      // setSelectedContacts(selectedOptions);
    }
  }, [opportunity, call, event, contact])

  // generateSubject
  useEffect(() => {
    if (subjectGeneratorActive) {
      let subject = ""
      if (opportunity) {
        subject = `${opportunity?.properties?.name} Call`
      } else if (event) {
        subject = `${event.name} Notes`
      } else {
        subject = `Conversation`
      }

      if (attendeeContacts.length > 0) {
        subject += " with "
        const contactNames = attendeeContacts.map(e => composedShortName(e))
        subject += contactNames.join(", ")
      }

      setName(subject)
    }
  }, [attendeeContacts])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)

    if (call?.ref) {
      call.subject = name
      call.details = description
      call.date = closeDate.toDate()
      call.contacts = attendeeContacts
      call.opportunityId = opportunity?.id

      await callsApi.updateCall(call, opportunity)
      setLoading(false)
      handleCloseDrawer()
      return
    }

    let newCall = new Call({
      subject: name,
      details: description,
      date: closeDate.toDate(),
      contacts: attendeeContacts,
      opportunityId: opportunity?.id,
      eventRef: event?.ref
    })

    await callsApi.createCall(newCall, opportunity)
    setLoading(false)
    handleCloseDrawer()
  }

  const handleSecondaryAction = async () => {
    if (opportunity) {
      await callsApi.deleteCall(call)
    } else {
      handleCloseDrawer()
    }
  }

  function composedShortName(contact: any): string {
    let fullName = ""

    fullName += contact.firstName
    if (contact.lastName != null && contact.lastName !== "") {
      fullName += " "
      fullName += contact.lastName.substring(0, 1)
      fullName += "."
    }

    return fullName.trim()
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any", // Can be 'anywhere' or 'start'
    stringify: (option: any) => `${option.name} ${option.emailAddress}`
  })

  const onContactAdded = (event: any, newValue: any[]) => {
    setAttendeeContacts(newValue)
  }

  const onContactRemoved = ({ removedContact }: { removedContact: any }) => {
    setAttendeeContacts(prevContacts => prevContacts.filter(contact => contact?.ref?.id != removedContact?.ref?.id))
  }

  return (
    <Drawer
      anchor="right"
      ref={drawerRef}
      open={openDrawer}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          height: "fit-content",
          maxHeight: "96%",
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      <CreateCommitmentDrawer
        openDrawer={openCreateCommitmentDrawer}
        handleCloseDrawer={handleCloseCommitmentDrawer}
        opportunity={opportunity}
        // priorityIds={[...(opportunity?.clientContactsIds ?? []), ...(opportunity?.teamContactsIds ?? [])]}
      />
      <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
        <Backdrop sx={{ background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "absolute" }} open={loading}>
          <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
        </Backdrop>

        {/* Header */}
        <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
          <MDBox>
            <MDTypography variant="h5">Log a Conversation</MDTypography>
          </MDBox>
          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)"
            })}
            onClick={handleCloseDrawer}
          >
            close
          </Icon>
        </MDBox>
        <Divider />

        {/* Body */}
        <MDBox>
          <MDBox style={{ width: "100%", flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MDBox px={3}>
                <MDBox className={classes.formTile}>
                  <CalendarMonth className={classes.formIcon} />
                  <MDBox style={{ width: "100%" }}>
                    <MDTypography variant="body2" color="text" style={{ alignSelf: "center" }}>
                      Who attended?
                    </MDTypography>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id="contacts-select"
                      sx={{ width: "100%" }}
                      style={{ flex: 1 }}
                      value={attendeeContacts}
                      options={contacts}
                      getOptionDisabled={option => attendeeContacts.find(contact => contact?.id === option?.id) !== undefined}
                      autoHighlight
                      getOptionLabel={option => option?.email ?? ""}
                      filterOptions={filterOptions}
                      renderTags={(value: any[]) => {
                        return (
                          <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                            {value.map((contact, index) => (
                              <ContactChip contact={contact} onContactRemoved={removedContact => onContactRemoved({ removedContact })} />
                            ))}
                            <Avatar
                              style={{
                                background: "cornflowerblue",
                                marginTop: 4,
                                width: "32px",
                                height: "32px",
                                cursor: "pointer"
                              }}
                            >
                              <PersonAdd fontSize="small" style={{ color: "white" }} />
                            </Avatar>
                          </Stack>
                        )
                      }}
                      onChange={(event, newValue) => onContactAdded(event, newValue)}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          <MDBox mr={2}>
                            <ForgeContactAvatar contact={option} enableScoreBadge={false} />
                          </MDBox>
                          <Stack direction="column">
                            {option.name}
                            <MDTypography variant="body2" fontSize="small" style={{ color: "gray", lineHeight: "1rem" }}>
                              {option.emailAddress}
                            </MDTypography>
                          </Stack>
                        </Box>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          className={classes.root}
                          label=""
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password" // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>
                <MDBox className={classes.formTile}>
                  <Abc className={classes.formIcon} />
                  <TextField
                    id="name"
                    label="Subject"
                    variant="outlined"
                    style={{ flex: 1 }}
                    value={name}
                    onChange={e => {
                      setSubjectGeneratorActive(false)
                      return setName(e.target.value)
                    }}
                  />
                </MDBox>
                <MDBox className={classes.formTile}>
                  <EditNoteOutlined className={classes.formIcon} />
                  <TextField
                    id="description"
                    label="Description"
                    variant="outlined"
                    style={{ flex: 1 }}
                    multiline
                    minRows={5}
                    maxRows={10}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </MDBox>
                <MDBox
                  bgColor={"grey-100"}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "row", sm: "row" }}
                  style={{ cursor: "pointer" }}
                  mb={2}
                  ml={5.5}
                  py={1}
                  pl={2}
                  pr={2}
                  onClick={handleOpenCommitmentDrawer}
                >
                  <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                    Have you committed to any next steps?
                  </MDTypography>
                  <OpenInNewRounded />
                </MDBox>
                <MDBox className={classes.formTile}>
                  <CalendarMonth className={classes.formIcon} />
                  <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                    Conversation Date
                  </MDTypography>
                  <Box style={{ flex: 1 }} />
                  <DatePicker format="MMM DD, YYYY" value={closeDate} onChange={setCloseDate} />
                </MDBox>
              </MDBox>
            </LocalizationProvider>
          </MDBox>

          {/* Footer */}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              p: 1.5,
              pb: 2,
              borderTop: "1px solid",
              borderColor: "divider",
              justifyContent: "space-between"
            }}
          >
            <MDButton variant="text" color={opportunity ? "error" : "dark"} onClick={handleSecondaryAction}>
              {call?.ref ? "Delete" : "Clear"}
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              {call?.ref ? "Update" : "Save"}
            </MDButton>
          </Box>
        </MDBox>
      </FormControl>
    </Drawer>
  )
}

// Setting default values for the props of CreateCall
CreateCallDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default CreateCallDrawer
