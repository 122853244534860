import { ArrowBackIos, ArrowBackIosNew, ArrowForwardIos, Done } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Stack } from "@mui/material"
import React, { useState } from "react"
import Circle from "./components/Circle"
import Relationship1Slide from "./components/Relationship1Slide"
import Relationship2Slide from "./components/Relationship2Slide"
import Relationship3Slide from "./components/Relationship3Slide"
import Relationship4Slide from "./components/Relationship4Slide"
import Relationship5Slide from "./components/Relationship5Slide"
import Relationship6Slide from "./components/Relationship6Slide"
import OnboardingProgress from "./components/OnboardingProgress"

function OnboardingDialog({ open, onContinue }: { open: boolean; onContinue: () => void }) {
  const [index, setIndex] = useState<number>(0)

  const renderSlide = () => {
    switch (index) {
      case 0:
        return <Relationship1Slide />
      case 1:
        return <Relationship2Slide />
      case 2:
        return <Relationship3Slide />
      case 3:
        return <Relationship4Slide />
      case 4:
        return <Relationship5Slide />
      case 5:
        return <Relationship6Slide />
    }
  }

  const onForward = () => {
    if (index >= 5) {
      onContinue()
      setIndex(0)
      return
    }

    setIndex(index + 1)
  }

  const onBackward = () => {
    setIndex(index - 1)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            boxShadow: "none",
            background: index === 0 || index === 5 ? "#3D79EE" : "white",
            minHeight: "490px"
          },
          sx: { borderRadius: "16px" }
        }}
      >
        <Box flex={1} />
        {renderSlide()}
        <DialogActions>
          {index !== 0 ? (
            <IconButton onClick={onBackward}>
              <ArrowBackIosNew style={{ color: index === 0 || index === 5 ? "white" : null }} />
            </IconButton>
          ) : (
            <Box width={24} />
          )}
          <Box flex={1} />
          <OnboardingProgress size={6} currentIndex={index} />
          <Box flex={1} />
          <IconButton onClick={onForward}>
            {index !== 5 ? <ArrowForwardIos style={{ color: index === 0 || index === 5 ? "white" : null }} /> : <Done style={{ color: "white" }} />}
          </IconButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default OnboardingDialog
