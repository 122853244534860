import { createContext, useContext, useState, useEffect, useRef } from "react"
import { AuthContext, CalendarsContext, ContactsContext } from "context"
import { DocumentReference, Timestamp, collection, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { CalendarEvent } from "types/calendar/calendar-event"
import { sortContacts } from "types/contact/contact"
import CalendarFirestoreService from "./firestore"
import EventsApi from "./api"
import { CalendarType } from "types/calendar/calendar"
import { addDays, startOfDay, subtractDays } from "forge/core/utilities"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import { Call } from "types/pipeline/call"

interface EventsContextType {
  events: CalendarEvent[]
  getEvent(eventId: string): CalendarEvent | null
  getEvents(eventIds: string[]): CalendarEvent[]
  getFutureEventsForContact(contactIds: string[]): CalendarEvent[]
  fetchEvents(): Promise<void>
  getEventCall(event: CalendarEvent): Promise<Call | undefined>
}

export const EventsContext = createContext<EventsContextType>({
  events: [],
  getEvent: (eventId: string): any => null,
  getEvents: (eventIds: string[]): any[] => [],
  getFutureEventsForContact: (contactIds: string[]): any[] => [],
  fetchEvents: async () => {},
  getEventCall: async () => undefined
})

export const EventsContextProvider = ({ children }: { children: any }) => {
  // Context
  const { warmthAlgorithm } = useContext(RemoteConfigContext)
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { calendars, getCalendarsByType } = useContext(CalendarsContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const contactsContextRef = useRef(getContacts) // Ref to hold the context value

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const calendarFirestoreService = new CalendarFirestoreService(user, userProfileData, encryptionService)
  const eventsApi = new EventsApi(user, userProfileData, encryptionService)

  // State
  const [events, setEvents] = useState<CalendarEvent[]>([])

  useEffect(() => {
    fetchEvents()
  }, [calendars])

  useEffect(() => {
    if (user) {
      const today = new Date()
      today.setDate(today.getDate() - 30)

      const unsubscribe = calendarFirestoreService.getEventsLive(today, (events: CalendarEvent[]) => {
        setEvents(matchContacts(events))
      })

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized])

  useEffect(() => {
    contactsContextRef.current = getContacts // Update the ref whenever the context value changes
    setEvents(matchContacts(events))
  }, [contacts])

  const fetchEvents = async () => {
    if (user) {
      const googleCalendars = getCalendarsByType(CalendarType.google)
      const outlookCalendars = getCalendarsByType(CalendarType.outlook)

      if (googleCalendars.length > 0 || outlookCalendars.length > 0) {
        // TODO: Migrate logic to backend
        // Next 7 days
        const now = new Date()
        let end = addDays(startOfDay(now), 8)
        await eventsApi.fetchEvents(googleCalendars, outlookCalendars, startOfDay(now), end)

        // Previous 7 days
        let start = subtractDays(startOfDay(now), 7)
        end = startOfDay(now)
        await eventsApi.fetchEvents(googleCalendars, outlookCalendars, start, end)

        // Next 30 days
        start = addDays(startOfDay(now), 7)
        end = addDays(start, 37)
        await eventsApi.fetchEvents(getCalendarsByType(CalendarType.google), getCalendarsByType(CalendarType.outlook), start, end)

        // Previous 30 days
        start = subtractDays(startOfDay(now), 37)
        end = subtractDays(startOfDay(now), 7)
        await eventsApi.fetchEvents(getCalendarsByType(CalendarType.google), getCalendarsByType(CalendarType.outlook), start, end)
      }
    }
  }

  const matchContacts = (events: CalendarEvent[]): CalendarEvent[] => {
    for (const event of events) {
      let attendeesContacts = contactsContextRef.current(event.properties.attendeesRefs?.map((e: DocumentReference) => e.id) ?? [])

      let attendeesResearchContacts = contactsContextRef.current(event.properties.attendeesResearchRefs?.map((e: DocumentReference) => e.id) ?? [])

      event.properties.attendees = event.properties.attendees.map((e, index) => {
        if (index < event.properties.attendeesRefs.length) {
          e.contact = attendeesContacts.find(e => e.ref?.id === event.properties.attendeesRefs[index].id)
        }

        return e
      })

      event.properties.attendeesResearch = event.properties.attendeesResearch.map((e, index) => {
        if (index < event.properties.attendeesResearchRefs.length) {
          e.contact = attendeesResearchContacts.find(e => e.ref?.id === event.properties.attendeesResearchRefs[index].id)
        }

        return e
      })

      event.contacts = sortContacts([...attendeesContacts, ...attendeesResearchContacts], warmthAlgorithm, user.uid)

      // if (event.name == "Test Opportunity") {
      //     console.log("Test Opportunity", event);
      // }
    }

    return events
  }

  const getEvent = (eventId: string): any => {
    return events.find(event => event.eventId === eventId)
  }

  const getEvents = (eventIds: string[]): any[] => {
    let selectedEvents = []

    for (const eventId of eventIds) {
      selectedEvents.push(events.find(event => event.eventId === eventId))
    }

    return selectedEvents.filter(e => e)
  }

  const getFutureEventsForContact = (contactIds: string[]): any[] => {
    let selectedEvents = events.filter(event => event.contacts.some(contact => contactIds.includes(contact.ref?.id)))

    return selectedEvents.filter(e => e && e.startDate.toDate() > new Date())
  }

  const getEventCall = async (event: CalendarEvent): Promise<Call | undefined> => {
    if (event.callRef) {
      const eventCall = await calendarFirestoreService.getEventCall(event.callRef)
      eventCall.contacts = getContacts(eventCall.contactRefs?.map((e: DocumentReference) => e.id) ?? [])

      return eventCall
    }

    return
  }

  return (
    <EventsContext.Provider
      value={{
        events,
        getEvent,
        getEvents,
        getFutureEventsForContact,
        fetchEvents,
        getEventCall
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}
