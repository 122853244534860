import Swal, { SweetAlertOptions } from "sweetalert2"
import { NOTIFICATION_TYPES } from "./constants"

interface SwalProps {
  position: string
  title: string
  showConfirmButton: boolean
  timer: number
  toast: boolean
  icon?: string
}

export const triggerToastNotification = ({
  notificationMessage,
  notificationType = "",
  position = "top-end"
}: {
  notificationMessage: string
  notificationType?: string
  position?: string
}) => {
  const swalProperties: SwalProps = {
    position: position,
    title: notificationMessage,
    showConfirmButton: false,
    timer: 2000,
    toast: true
  }

  if (notificationType && Object.values(NOTIFICATION_TYPES).includes(notificationType)) swalProperties.icon = notificationType
  Swal.fire(swalProperties as unknown as SweetAlertOptions)
}
