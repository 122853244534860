import { createContext, useContext, useState, useEffect } from "react"
import { AuthContext } from "context"
import { UserPipelineConfig } from "types/user/user-pipeline-config"
import SettingsApi from "./api"
import Nango from "@nangohq/frontend"
import { Crm } from "types/pipeline/crm"
import PipelineApi from "forge/pipeline/opportunities/services/api"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"

interface CrmContextType {
  pipelineConfig?: UserPipelineConfig
  organizationPipelineConfig?: UserPipelineConfig
  loading: boolean
  syncingCrm: Crm
  showNotification: boolean
  notificationMessage: string
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>
  createConnection: (crm: Crm, isOrganizationConnection?: boolean) => any
  deleteConnection: (crm: Crm, isOrganizationConnection?: boolean) => Promise<any>
}

export const CrmContext = createContext<CrmContextType>({
  pipelineConfig: null,
  organizationPipelineConfig: null,
  loading: false,
  syncingCrm: undefined,
  showNotification: false,
  notificationMessage: undefined,
  setShowNotification: undefined,
  createConnection: () => {},
  deleteConnection: async () => {}
})

export const CrmContextProvider = ({ contact, children }: { contact?: any; children: any }) => {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const settingsApi = new SettingsApi(user, userProfileData, encryptionService)
  const pipelineApi = new PipelineApi(user, userProfileData, encryptionService)
  let nango = new Nango({ publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY })

  // State
  const [organizationPipelineConfig, setOrganizationPipelineConfig] = useState<UserPipelineConfig>()
  const [pipelineConfig, setPipelineConfig] = useState<UserPipelineConfig>()
  const [loading, setLoading] = useState<boolean>(false)
  const [syncingCrm, setSyncingCrm] = useState<Crm>()
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [notificationMessage, setNotificationMessage] = useState<string>()

  useEffect(() => {
    if (organization?.pipelineConfig?.isEnabled) {
      setOrganizationPipelineConfig(
        new UserPipelineConfig({
          isEnabled: organization.pipelineConfig.isEnabled,
          integration: organization.pipelineConfig.integration,
          enableDate: organization.pipelineConfig.enableDate
        })
      )
    }

    setPipelineConfig(userProfileData?.pipelineUserConfig)
  }, [userProfileData, organization?.pipelineConfig])

  const createConnection = async (crm: Crm, isOrganizationConnection: boolean = false): Promise<any> => {
    let response
    setLoading(true)
    setSyncingCrm(crm)
    try {
      let result = await nango.auth(crm, user.uid)
      // Show success UI.
      console.log(result)

      if (isOrganizationConnection) {
        response = await settingsApi.createOrganizationConnection(crm)
      } else {
        response = await settingsApi.verifyOrganizationMembership(crm)
      }

      // Show Notification
      setNotificationMessage(response.message)
      setShowNotification(true)

      // Stop animations
      setSyncingCrm(undefined)
      setLoading(false)

      if (response.success) {
        // Load initial opportunities
        pipelineApi.getOpportunities()
      }
    } catch (error) {
      // Show failure UI.
      console.error(error)
      setSyncingCrm(undefined)
      setLoading(false)
    }
  }

  const deleteConnection = async (crm: Crm, isOrganizationConnection: boolean = false): Promise<any> => {
    setLoading(true)
    setSyncingCrm(crm)
    await pipelineApi.deleteConnection(crm)
    setSyncingCrm(undefined)
    setLoading(false)
  }

  return (
    <CrmContext.Provider
      value={{
        pipelineConfig,
        organizationPipelineConfig,
        loading,
        syncingCrm,
        showNotification,
        notificationMessage,
        setShowNotification,
        createConnection,
        deleteConnection
      }}
    >
      {children}
    </CrmContext.Provider>
  )
}
