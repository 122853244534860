import { Card, Divider, FormControl, TextField, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import ExperienceTile from "../ExperienceTile"
import EducationTile from "../EducationTile"
import {
  BusinessCenterOutlined,
  InfoRounded,
  LinkedIn,
  LocationOn,
  SchoolOutlined,
  SummarizeOutlined,
  VolunteerActivismOutlined
} from "@mui/icons-material"
import { createUseStyles } from "react-jss"
import VolunteerWorkTile from "../VolunteerWorkTile"
import LocationTile from "../LocationTile"
import { useContext, useEffect, useState } from "react"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { LinkedInProfileContext } from "../../services/LinkedInProfileContext"
import LinkedInOptionCard from "../LinkedInOptionCard"
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option"
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import ForgeCta from "forge/core/components/ForgeCta"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"

const styles = createUseStyles({
  lightGray: { color: "darkgray" }
})

function ContactResearchCard({
  contact,
  handleOpenDetailDrawer
}: {
  contact: any
  handleOpenDetailDrawer: (knowledge: any, linkedInData?: any) => void
}) {
  const classes = styles()

  // Context
  const { companyKnowledge, locationKnowledge, summaryKnowledge, educationKnowledge, experienceKnowledge, volunteerWorkKnowledge } =
    useContext(KnowledgeContext)
  const { profileOptions, selectProfile, setLinkedInUrl } = useContext(LinkedInProfileContext)

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    setUrl("")
  }, [contact?.ref])

  function lacksData() {
    return (
      !(contact?.linkedInProfileData?.openai_summary?.text && contact?.linkedInProfileData?.openai_summary?.text.length > 0) &&
      !(companyKnowledge && companyKnowledge.length > 0) &&
      !(locationKnowledge && locationKnowledge.length > 0) &&
      !(summaryKnowledge && summaryKnowledge.length > 0) &&
      !(educationKnowledge && educationKnowledge.length > 0) &&
      !(experienceKnowledge && experienceKnowledge.length > 0) &&
      !(volunteerWorkKnowledge && volunteerWorkKnowledge.length > 0)
    )
  }

  function headerQuestion() {
    if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return `We're looking for ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile) {
          return `Researching ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
        } else if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return `We're looking for ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
        } else {
          return null
        }
      } else {
        return `What do you have in common with ${contact.firstName}?`
      }
    } else if (profileOptions.length > 0) {
      return `Which LinkedIn Profile is ${contact.firstName}'s, if any?`
    }

    return `We couldn't find ${contact.firstName}'s profile. Paste the URL here.`
  }

  function renderBody() {
    if (!contact || contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return <img src={spinningSphere} alt="loading" style={{ width: "40%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile || contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return <img src={spinningSphere} alt="loading" style={{ width: "40%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
        } else {
          // Visit Profile
          return <ForgeCta cta="Visit LinkedIn Profile" icon={<LinkedIn />} onClick={() => window.open(contact?.linkedInUrl, "_blank")} />
        }
      } else {
        return <></>
      }
    } else if (profileOptions.length > 0) {
      return profileOptions.map(option => <LinkedInOptionCard linkedInOption={option} onClick={() => onLinkedInOptionSelected(option)} />)
    }

    return (
      <FormControl style={{ width: "100%" }} component="form" onSubmit={handleSubmit}>
        <TextField
          id="linkedIn"
          label="LinkedIn URL"
          variant="outlined"
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{
            endAdornment: (
              <Tooltip
                title={`Navigate to the LinkedIn profile you want to copy. Once on the profile page, look at the browser's address bar at the top, where you'll see the URL of the profile. Click on the address bar to highlight the entire URL, then right-click and select "Copy," or simply press Ctrl+C on your keyboard. You can now paste the copied URL.`}
                placement="bottom"
              >
                <InfoRounded fontSize="small" />
              </Tooltip>
            )
          }}
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </FormControl>
    )
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    if (url) {
      await setLinkedInUrl(url)
    }

    setLoading(false)
  }

  const onLinkedInOptionSelected = (linkedInOption: LinkedInProfileOption) => {
    selectProfile(linkedInOption)
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Research" tooltipInfo={CARDS_TOOLTIPS.RESEARCH} />

        <Divider style={{ opacity: 1 }} />

        {contact?.firstName && (
          <MDTypography variant="h6" textAlign="center" mb={1.5}>
            {headerQuestion()}
          </MDTypography>
        )}
        {!contact?.linkedInProfileData?.openai_summary?.moderationFlagged &&
          contact?.linkedInProfileData?.openai_summary?.text &&
          contact?.linkedInProfileData?.openai_summary?.text.length > 0 && (
            <MDBox>
              <MDBox display="flex" alignItems="center" pr={3} lineHeight={1}>
                <SummarizeOutlined className={classes.lightGray} />
                <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                  {`${contact.firstName}'s Career Summary`}
                </MDTypography>
              </MDBox>
              <MDTypography
                variant="body2"
                color="text"
                textAlign="justify"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenDetailDrawer(summaryKnowledge)}
              >
                {parseKnowledge(summaryKnowledge, contact?.linkedInProfileData?.openai_summary?.text)}
              </MDTypography>
              <Divider variant="middle" />
            </MDBox>
          )}

        {locationKnowledge && locationKnowledge.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <LocationOn className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Location
              </MDTypography>
            </MDBox>

            {locationKnowledge.map((e: any) => (
              <LocationTile key={e.id} location={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Education */}
        {contact?.linkedInProfileData?.education && contact?.linkedInProfileData?.education.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <SchoolOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Education
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.education?.map((e: any) => (
              <EducationTile key={e.id} education={e} knowledge={educationKnowledge} onClick={() => handleOpenDetailDrawer(educationKnowledge)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Experience  */}
        {contact?.linkedInProfileData?.experiences && contact?.linkedInProfileData?.experiences.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <BusinessCenterOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Experience
              </MDTypography>
            </MDBox>

            {companyKnowledge.map((e: any) => (
              <ExperienceTile key={e.id} knowledge={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            {contact?.linkedInProfileData?.experiences?.map((e: any) => (
              <ExperienceTile key={e.id} experience={e} knowledge={experienceKnowledge} onClick={() => handleOpenDetailDrawer(experienceKnowledge)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Volunteer Work   */}
        {contact?.linkedInProfileData?.volunteer_work && contact?.linkedInProfileData?.volunteer_work.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <VolunteerActivismOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Volunteer Work
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.volunteer_work?.map((e: any) => (
              <VolunteerWorkTile
                key={e.id}
                volunteerWork={e}
                knowledge={volunteerWorkKnowledge}
                onClick={() => handleOpenDetailDrawer(volunteerWorkKnowledge)}
              />
            ))}
          </MDBox>
        )}

        {renderBody()}
      </MDBox>
    </Card>
  )
}

export default ContactResearchCard
