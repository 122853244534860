import React from "react"
import { Backdrop, Stack, useMediaQuery } from "@mui/material"
import MDTypography from "components/MDTypography"
import theme from "assets/theme"

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"

interface Props {
  loading: boolean
  customHeight?: string
  customWidth?: string
  customTop?: string
  loadingText?: string
  loadingType?: string
  style?: React.CSSProperties
}

const ForgeLoading: React.FC<Props> = ({ loading, customHeight, customWidth, customTop, loadingText = "", loadingType = "", style }) => {
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  if (loadingType === "small") return <img hidden={!loading} src={spinningSphere} style={{ width: "40px", ...style }} alt="Loading..." />

  const height = customHeight ?? "95vh"
  const width = customWidth ?? largeScreen ? "40%" : "68%"
  const top = customTop ?? "35vh"

  return (
    <div style={{ height, width, background: "rgba(255, 255, 255, 0.5)", position: "fixed", zIndex: "99" }} hidden={!loading}>
      <Backdrop sx={{ top, background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "sticky" }} open={loading}>
        <Stack direction="column" spacing={1}>
          <img src={spinningSphere} style={{ width: "200px", ...style }} alt="Loading..." />
          <MDTypography variant="h6" textAlign="center">
            {loadingText}
          </MDTypography>
        </Stack>
      </Backdrop>
    </div>
  )
}

export default ForgeLoading
