import { AbilityBuilder, createMongoAbility } from "@casl/ability"

export default function defineAbilityFor(doesUserBelongsToAnOrganization: boolean, role: string, userId: string) {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  // Define sections available for users
  switch (userId) {
    // case "l46DLgW230dW8H75jM5i2YVNCkN2":
    case "7YZiqhhboeUnDkdJZ60oT4GrHTs1":
    case "qMRcJKdo3MdIeYXsmv2RWVWwxHn2":
    case "QYJKeOLYUuQ0a5Uh5LAkUsAjRZP2":
    case "s52iBhT2HUYhsHyUF60bprNX8lf1": // Charlie
    case "IH6bBe2NNHajnLW6DXsEpbn1pjm2": // Joaquin
    case "0Dn1NiXqCadFo4LIyBh1V6DSTyh1": // Erick
    case "Xm7Qazyt8pNOosQ4W6imPpTG8Vj2": // Singular Testing
      // Organization
      can("use", "organization")
      break
    default:
      cannot("use", "organization")
      break
  }

  can("use", "home")
  can("use", "people")
  can("use", "pipeline")
  can("use", "outreach")
  can("use", "settings")
  can("use", "logout")

  if (!doesUserBelongsToAnOrganization) {
    // Set role as admin if user doesn't belong to an organization.
    role = "admin"
  }

  // Define actions available for users
  switch (role) {
    case "admin":
      // Organization
      can("view", "organization")
      can("edit", "organization")
      can("delete", "organization")
      cannot("leave", "organization")

      can("view", "divisions")
      can("view", "crm")

      // Billing
      can("view", "billing")
      can("edit", "billing")

      // Teams
      can("view", "teams")
      can("create", "teams")
      can("edit", "teams")
      can("add-members", "teams")
      can("delete", "teams")

      // Members
      can("view", "members")
      can("invite", "members")
      can("delete", "members")
      can("edit-role", "members")

      // Roles
      can("view", "roles")

      // Contacts
      can("create", "contacts")
      can("view", "contacts")
      can("edit", "contacts")
      can("merge", "contacts")
      can("ignore", "contacts")
      can("delete", "contacts")

      // Knowledge
      can("create", "knowledge")
      can("view", "knowledge")
      can("edit", "knowledge")
      can("delete", "knowledge")
      break
    case "manager":
      // Organization
      can("view", "organization")
      cannot("edit", "organization")
      cannot("delete", "organization")
      can("leave", "organization")

      can("view", "divisions")
      can("view", "crm")

      // Billing
      cannot("view", "billing")
      cannot("edit", "billing")

      // Teams
      can("view", "teams")
      can("create", "teams")
      can("edit", "teams")
      can("add-members", "teams")
      cannot("delete", "teams")

      // Members
      can("view", "members")
      cannot("invite", "members")
      cannot("delete", "members")
      cannot("edit-role", "members")

      // Roles
      can("view", "roles")

      // Contacts
      can("create", "contacts")
      can("view", "contacts")
      can("edit", "contacts")
      can("merge", "contacts")
      can("ignore", "contacts")
      can("delete", "contacts")

      // Knowledge
      can("create", "knowledge")
      can("view", "knowledge")
      can("edit", "knowledge")
      can("delete", "knowledge")
      break
    case "representative":
      // Organization
      can("view", "organization")
      cannot("edit", "organization")
      cannot("delete", "organization")
      can("leave", "organization")

      can("view", "divisions")
      can("view", "crm")

      // Billing
      cannot("view", "billing")
      cannot("edit", "billing")

      // Teams
      can("view", "teams")
      cannot("create", "teams")
      cannot("edit", "teams")
      cannot("add-members", "teams")
      cannot("delete", "teams")

      // Members
      can("view", "members")
      cannot("invite", "members")
      cannot("delete", "members")
      cannot("edit-role", "members")

      // Roles
      can("view", "roles")

      // Contacts
      can("create", "contacts")
      can("view", "contacts")
      cannot("edit", "contacts")
      cannot("merge", "contacts")
      cannot("ignore", "contacts")
      cannot("delete", "contacts")

      // Knowledge
      can("create", "knowledge")
      can("view", "knowledge")
      cannot("edit", "knowledge")
      cannot("delete", "knowledge")
      break
  }

  return build()
}
