// @mui material components
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Setting pages components
import { Divider, Radio } from "@mui/material"
import NotificationTile from "./NotificationTile"
import { useContext } from "react"
import { AuthContext } from "context"
import { SettingsContext } from "forge/settings/services/SettingsContext"

function SettingsNotifications(): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { userSettings } = getCurrentUser()
  const { updateUserSettings } = useContext(SettingsContext)

  return (
    <Card id="notifications">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Notifications</MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          How frequently do you want reminders?
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <NotificationTile
          text="Daily look-ahead preparation"
          isEnabled={userSettings?.frequenceDailyPrePush}
          onEnabled={enabled =>
            updateUserSettings(
              userSettings?.copyWith({
                frequenceDailyPrePush: enabled
              })
            )
          }
        />
        <Divider />
        <NotificationTile
          text="Prepare before each meeting"
          isEnabled={userSettings?.frequenceBeforeEachPush}
          onEnabled={enabled => {
            updateUserSettings(
              userSettings?.copyWith({
                frequenceBeforeEachPush: enabled
              })
            )
          }}
        />
        <Divider />
        <NotificationTile
          text="Save Insights after each meeting"
          isEnabled={userSettings?.frequenceAfterEachPush}
          onEnabled={enabled => {
            updateUserSettings(
              userSettings?.copyWith({
                frequenceAfterEachPush: enabled
              })
            )
          }}
        />
        <Divider />
        <NotificationTile
          text="End of day look-back"
          isEnabled={userSettings?.frequenceDailyPostPush}
          onEnabled={enabled => {
            updateUserSettings(
              userSettings?.copyWith({
                frequenceDailyPostPush: enabled
              })
            )
          }}
        />
        <Divider />
        <NotificationTile
          text="Commitments"
          isEnabled={userSettings?.commitmentsPush}
          onEnabled={enabled => {
            updateUserSettings(
              userSettings?.copyWith({
                commitmentsPush: enabled
              })
            )
          }}
        />
        <Divider />
        <NotificationTile
          text="Milestones"
          isEnabled={userSettings?.milestonesPush}
          onEnabled={enabled => {
            updateUserSettings(
              userSettings?.copyWith({
                milestonesPush: enabled
              })
            )
          }}
        />
        <Divider />
      </MDBox>
      <MDBox px={3} pb={3} lineHeight={1}>
        <MDTypography variant="body2" color="text">
          Who do you want to receive notifications about?
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection="row">
          <MDTypography variant="button" color="text">
            All people
          </MDTypography>
          <MDBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <Radio />
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection="row">
          <MDTypography variant="button" color="text">
            Priority people only
          </MDTypography>
          <MDBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <Radio />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default SettingsNotifications
