import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, TextField, createFilterOptions } from "@mui/material"
import MDBox from "components/MDBox"
import { Edit } from "@mui/icons-material"
import { ListenContext } from "../../services/ListenContext"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import MDButton from "components/MDButton"
import { createUseStyles } from "react-jss"
import { DatePicker, LocalizationProvider, YearCalendar } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { Question } from "types/question"
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import { ContactsContext } from "context"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { getBachelors } from "types/contact/linkedin-data"

const styles = createUseStyles({
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    },
    "& .Mui-selected": {
      background: "black"
    }
  }
})

function QuestionField({ question, contact }: { question: Question; contact: any }) {
  // UI
  const classes = styles()

  // Context
  const { saveAnswer } = useContext(ListenContext)
  const { contacts } = useContext(ContactsContext)

  // State
  const inputRef = useRef(null)
  const [possibleAnswers, setPossibleAnswers] = useState<string[]>([])
  const [focused, setFocused] = useState(false)
  const focusedRef = useRef(focused)
  const [loading, setLoading] = useState(false)
  const onFocus = () => {
    if (!answer && question.prefill) {
      setAnswer(question.prefill)
    }

    return setFocused(true)
  }
  const onBlur = () => setFocused(false)

  const [answer, setAnswer] = useState<string>()
  const answerRef = useRef(answer)
  const [date, setDate] = useState<Dayjs>()
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean[]>([])
  const [selectedContact, setSelectedContact] = useState<any>()

  const keyboardListener = useCallback(async (event: any) => {
    if (focusedRef.current) {
      if (event.key === "Escape") {
        handleClear()
      } else if (event.key === "Enter") {
        await handleSave()
        setDate(null)
        clearInput()
      }
    }
  }, [])

  useEffect(() => {
    focusedRef.current = focused
  }, [focused])

  useEffect(() => {
    answerRef.current = answer
  }, [answer])

  useEffect(() => {
    if (question.notNullPredecessors.includes("school1")) {
      const linkedInBachelors = getBachelors(contact?.linkedInProfileData?.education)

      if (!contact?.questionsVars) {
        contact.questionsVars = {}
      }

      contact.questionsVars["school1"] = contact.questionsVars["school1"] ?? linkedInBachelors?.school
    }
  }, [question])

  useEffect(() => {
    document.addEventListener("keydown", keyboardListener, false)

    return () => {
      document.removeEventListener("keydown", keyboardListener, false)
    }
  }, [keyboardListener])

  useEffect(() => {
    if (question.possibleAnswers) {
      // Options
      setPossibleAnswers(question.renderQuestionVars(contact?.questionsVars))

      // Checkbox
      let checkboxState = []
      for (const possibleAnswer of question.possibleAnswers) {
        checkboxState.push(false)
      }
      setSelectedCheckbox(checkboxState)
    }
  }, [question?.possibleAnswers])

  const handleSave = async (answerOverride?: string) => {
    const finalAnswer = answerOverride ?? answerRef.current
    if (finalAnswer?.trim() || selectedContact) {
      setLoading(true)
      await saveAnswer(finalAnswer, question, selectedContact)
      setLoading(false)
    }
  }

  const clearInput = () => {
    if (inputRef?.current) {
      inputRef.current.value = ""
      inputRef.current.blur()
    }
    setAnswer(null)
    setInputFocus(false)
  }

  const setInputFocus = (state: boolean) => {
    const notEmpty = !!inputRef.current?.value

    if (notEmpty) return setFocused(true)

    setFocused(state)
  }

  const updateCheckboxValue = (index: number, newValue: boolean) => {
    setSelectedCheckbox(prevState => {
      // Create a copy of the state array
      const newArray = [...prevState]
      // Update the value at the specified index
      newArray[index] = newValue
      // Return the new array to update the state
      return newArray
    })
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any", // Can be 'anywhere' or 'start'
    stringify: (option: any) => option.name
  })

  useEffect(() => {
    let selectedOptions = []
    for (let i = 0; i < possibleAnswers.length; i++) {
      const option = possibleAnswers[i]
      if (selectedCheckbox[i]) {
        selectedOptions.push(option)
      }
    }

    setAnswer(selectedOptions.join(", "))
  }, [selectedCheckbox])

  const handleClear = async () => {
    if (question.type === "QuestionType.people") {
      await handleSave("nobody")
    }
    setDate(null)
    clearInput()
    setFocused(false)
    setAnswer(undefined)
  }

  const renderQuestionInput = (type: string) => {
    switch (type) {
      case "QuestionType.open":
      case "QuestionType.openFIDB":
      case "QuestionType.button":
      case "QuestionType.checkbox":
      case "QuestionType.pickerYears":
        return (
          <TextField
            key={question.id}
            inputRef={inputRef}
            focused={focused}
            onFocus={() => {
              if (type === "QuestionType.pickerYears" && !date) {
                setDate(dayjs())
                setAnswer(
                  dayjs().toDate().toLocaleDateString("en-US", {
                    year: "numeric"
                  })
                )
              }

              return onFocus()
            }}
            onBlur={onBlur}
            InputLabelProps={{ shrink: focused, style: { color: "gray" } }}
            id={question.id}
            label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
            variant="outlined"
            style={{ flex: 1, width: "100%" }}
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            autoComplete="off"
            // Keep this line (onKeyDown) or the keyboardListener won't work
            onKeyDown={async event => {}}
            InputProps={{
              readOnly: type !== "QuestionType.open" && type !== "QuestionType.openFIDB",
              endAdornment: loading ? <img src={spinningSphere} alt="loading" style={{ height: "44px" }} /> : <Edit fontSize="small" />
            }}
          />
        )
      case "QuestionType.picker":
        return (
          <Autocomplete
            key={question.id}
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            id={question.id}
            sx={{ width: "100%" }}
            style={{ flex: 1 }}
            options={possibleAnswers ?? []}
            autoHighlight
            getOptionLabel={(option: string) => option}
            value={answer}
            onChange={(event: any, newValue: string) => setAnswer(newValue)}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                {option}
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.root}
                label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
                InputLabelProps={{ shrink: focused, style: { color: "gray" } }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
        )
      case "QuestionType.datePicker":
        return (
          <DatePicker
            key={question.id}
            inputRef={inputRef}
            onOpen={onFocus}
            onChange={value => {
              if (value) {
                setDate(value)
                setAnswer(
                  "on " +
                    value.toDate().toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric"
                    })
                )
              }
            }}
            sx={{ width: "100%" }}
            label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
            format="MM/DD/YY"
            value={date}
            // onClose={() => { if (!date) onBlur() }}
          />
        )
      case "QuestionType.people":
        return (
          <Autocomplete
            id={question.id}
            sx={{ width: "100%" }}
            style={{ flex: 1 }}
            options={contacts}
            value={selectedContact}
            onChange={(event: any, newValue: any) => setSelectedContact(newValue)}
            autoHighlight
            getOptionLabel={option => option.name}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.ref?.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                <MDBox mr={2}>
                  <ForgeContactAvatar contact={option} enableScoreBadge={false} enableImageClick={false} />
                </MDBox>
                {option.name}
              </Box>
            )}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  placeholder={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password" // disable autocomplete and autofill
                  }}
                  onFocus={() => {
                    return onFocus()
                  }}
                />
              )
            }}
          />
        )
    }
  }

  const saveAndClearButtons = ({ clearText = "Clear", question }: { clearText?: string; question: any }) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: 4 }}>
        <MDButton variant="gradient" color="dark" size="small" type="submit" onClick={handleClear} style={{ textTransform: "none" }}>
          {clearText}
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          type="submit"
          onClick={async () => {
            await handleSave()
            setDate(null)
            clearInput()
          }}
          onMouseDown={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{ textTransform: "none" }}
        >
          Save
        </MDButton>
      </Stack>
    )
  }

  const renderQuestionButtons = (question: any) => {
    switch (question.type) {
      case "QuestionType.open":
      case "QuestionType.openFIDB":
      case "QuestionType.picker":
      case "QuestionType.datePicker":
      case "QuestionType.people":
        return saveAndClearButtons({
          clearText: question.type === "QuestionType.people" ? "Nobody" : "Clear",
          question
        })
      case "QuestionType.pickerYears":
        return (
          <Stack
            direction="column"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: 4 }}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <YearCalendar
              sx={{ width: "100%" }}
              className={classes.root}
              maxDate={dayjs()}
              onChange={value => {
                setDate(value)
                setAnswer(
                  value.toDate().toLocaleDateString("en-US", {
                    year: "numeric"
                  })
                )
              }}
            />
            {saveAndClearButtons({ question })}
          </Stack>
        )
      case "QuestionType.button":
        return (
          <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: 4 }}>
            {possibleAnswers?.map((possibleAnswer: string) => (
              <MDButton
                id={possibleAnswer}
                variant="gradient"
                color="info"
                size="small"
                onClick={async e => {
                  await handleSave(possibleAnswer)
                  setDate(null)
                  clearInput()
                }}
                onMouseDown={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ textTransform: "none" }}
              >
                {possibleAnswer}
              </MDButton>
            ))}
          </Stack>
        )
      case "QuestionType.checkbox":
        return (
          <Stack
            direction="column"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: 4 }}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {possibleAnswers?.map((possibleAnswer: string, index: number) => (
                  <FormControlLabel
                    control={<Checkbox checked={selectedCheckbox[index]} onChange={() => updateCheckboxValue(index, !selectedCheckbox[index])} />}
                    label={possibleAnswer}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {saveAndClearButtons({ question })}
          </Stack>
        )
    }
  }

  return (
    <Stack width="100%" mb={2} direction="column" alignItems="center" spacing={1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {renderQuestionInput(question.type)}
        {(focused || answer) && renderQuestionButtons(question)}
      </LocalizationProvider>
    </Stack>
  )
}

export default QuestionField
