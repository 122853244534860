import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import FormField from "layouts/pages/account/components/FormField"
import { useContext, useEffect, useState } from "react"
import { Autocomplete, Backdrop, Box, Stack } from "@mui/material"
import MDButton from "components/MDButton"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { ForgeOrganization } from "forge/organization/types/organization"

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import { AuthContext } from "context"
import { getDomainFromEmail } from "forge/core/utilities"
import ContactsApi from "forge/people/contacts/services/api"
import { UserEmail } from "types/user/user-email"
import MDTypography from "components/MDTypography"

export default function CreateOrganizationDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  // Context
  const { getCurrentUser, usersNonCommonDomainEmails } = useContext(AuthContext)
  const { createOrganization, queryDomain, verifyDomainAvailability } = useContext(OrganizationContext)

  // Services
  const { userProfileData } = getCurrentUser()

  // State
  const [domain, setDomain] = useState<string>()
  const [email, setEmail] = useState<UserEmail>()
  const [emails, setEmails] = useState<UserEmail[]>([])
  const [domainAvailability, setDomainAvailability] = useState<string[]>([])
  const [isUserDomain, setIsUserDomain] = useState<boolean>(false)
  const [company, setCompany] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>()
  const [legalName, setLegalName] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [linkedInUrl, setLinkedInUrl] = useState<string>()

  useEffect(() => {
    let emails = usersNonCommonDomainEmails()
    if (emails && emails.length > 0) {
      setEmails(emails)
      setIsUserDomain(true)
      ;(async () => {
        const domainsToVerify = emails.map(email => getDomainFromEmail(email.email))
        const availableDomains = await verifyDomainAvailability(domainsToVerify)
        setDomainAvailability(availableDomains)
      })()
    }
  }, [userProfileData?.emails, open])

  useEffect(() => {
    if (email) {
      setDomain(getDomainFromEmail(email.email))
    }
  }, [email])

  useEffect(() => {
    ;(async () => {
      if (domain) {
        let result = await queryDomain(domain)
        console.log(result)
        setCompany(result)
      }
    })()
  }, [domain])

  useEffect(() => {
    if (company) {
      setName(company.name)
      setLegalName(company.legalName)
      setAddress(company.location)
      if (company.linkedin?.handle) {
        setLinkedInUrl(`https://linkedin.com/${company.linkedin?.handle}`)
      }
    }
  }, [company])

  const onSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    if (domain) {
      const organization = new ForgeOrganization({
        domain: domain,
        name,
        legalName,
        address,
        linkedInUrl
      })
      await createOrganization(organization)
    }

    setLoading(false)
    internalHandleClose()
  }

  const internalHandleClose = () => {
    setDomain(undefined)
    setEmail(undefined)
    setName(undefined)
    setLegalName(undefined)
    setAddress(undefined)
    setLinkedInUrl(undefined)
    setLoading(false)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={internalHandleClose} fullWidth>
      <Backdrop sx={{ background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "absolute" }} open={loading}>
        <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
      </Backdrop>
      <DialogTitle>Add Organization</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Autocomplete
            id="domain-select"
            sx={{ width: "100%" }}
            style={{ flex: 1 }}
            options={emails}
            autoHighlight
            getOptionLabel={option => option.email}
            value={email}
            onChange={(event: any, newValue: UserEmail) => setEmail(newValue)}
            getOptionDisabled={option => !option.verified || !domainAvailability.includes(getDomainFromEmail(option.email))}
            renderOption={(props, option) => {
              const domain = getDomainFromEmail(option.email)

              return (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  <Stack direction="column">
                    {domain}
                    <MDTypography variant="body2" fontSize="small">
                      {!option.verified && "Email address is not verified"}
                      {option.verified && !domainAvailability.includes(domain) && "An organization already exist for the this domain"}
                    </MDTypography>
                  </Stack>
                </Box>
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Choose a domain"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormField
            label="Common Name"
            placeholder="Organization Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value)
            }}
          />
          <FormField
            label="Legal Name"
            placeholder="Legal Name"
            value={legalName}
            onChange={(e: any) => {
              setLegalName(e.target.value)
            }}
          />
          <FormField
            label="Address"
            placeholder="Address"
            value={address}
            onChange={(e: any) => {
              setAddress(e.target.value)
            }}
          />
          <FormField
            label="Company's LinkedIn Profile"
            placeholder="https://www.linkedin.com/in/..."
            value={linkedInUrl}
            onChange={(e: any) => {
              setLinkedInUrl(e.target.value)
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" autoFocus onClick={internalHandleClose} color={"dark"}>
          Cancel
        </MDButton>
        <MDButton onClick={onSubmit} color={"dark"} disabled={!domain || !name}>
          Add
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}
