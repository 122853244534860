import React from "react"
import { Tooltip } from "@mui/material"
import { InfoRounded } from "@mui/icons-material"
import MDTypography from "components/MDTypography"
import "./index.css"
import MDBox from "components/MDBox"

interface TooltipInfo {
  description: string
  benefits?: string
}

interface Props {
  title: string
  tooltipInfo: TooltipInfo
}

const CardTitle: React.FC<Props> = ({ title, tooltipInfo }) => {
  return (
    <MDBox display="flex" sx={{ justifyContent: "space-between" }}>
      <MDTypography variant="h5">{title}</MDTypography>
      <Tooltip
        style={{ marginLeft: "4px", color: "gray" }}
        title={
          <div className="tooltipContainer">
            {tooltipInfo.description}
            {tooltipInfo?.benefits && (
              <>
                <br />
                <br />
                Key benefits: {tooltipInfo?.benefits}
              </>
            )}
          </div>
        }
        placement="bottom-start"
        componentsProps={{ tooltip: { sx: { width: "100rem !important" } } }}
      >
        <InfoRounded fontSize="small" />
      </Tooltip>
    </MDBox>
  )
}

export default CardTitle
