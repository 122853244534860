import { useContext, useEffect, useState } from "react"

// react-router-dom components
import { Link } from "react-router-dom"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout"

// Context
import { AuthContext } from "context"

// Image
import bgImage from "assets/forge/images/forge.png"
import { TextField } from "@mui/material"
import MDButton from "components/MDButton"

function CDAKInput(): JSX.Element {
  // Context
  const { logout, initializeEThreeWithPassword } = useContext(AuthContext)

  // State
  const [key, setKey] = useState<string>("")

  return (
    <IllustrationLayout
      title="Cross Device Access Key"
      description="To access Forge on this new device or browser, please enter your Cross-Device Access Key found on your other device: Home > Settings > Account > Cross-Device Access Key"
      illustration={bgImage}
    >
      <TextField
        value={key}
        onChange={e => setKey(e.target.value)}
        autoFocus
        margin="dense"
        id="key"
        name="key"
        label="Cross Device Access Key"
        type="password"
        fullWidth
        variant="outlined"
        InputLabelProps={{
          style: { color: "gray" }
        }}
      />

      <MDButton
        variant="gradient"
        color="info"
        onClick={() => initializeEThreeWithPassword(key)}
        style={{ margin: "0 auto", display: "block", marginTop: "24px" }}
      >
        Validate
      </MDButton>

      <MDBox mt={3} textAlign="center">
        <MDTypography variant="button" color="text">
          Wrong account?{" "}
          <MDTypography onClick={logout} variant="button" color="info" fontWeight="medium" textGradient>
            Logout
          </MDTypography>
        </MDTypography>
      </MDBox>
    </IllustrationLayout>
  )
}

export default CDAKInput
