import { useContext, useEffect, useState } from "react"
import { CalendarsContext, EventsContext } from "context"
import { useParams, useSearchParams } from "react-router-dom"

// @mui material components
import { useMediaQuery, Stack, Divider } from "@mui/material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"

// Components
import ContactOverview from "forge/people/contacts/contact-overview"
import CreateEvent from "../components/CreateEventDrawer"
import theme from "assets/theme"
import { CalendarEvent } from "types/calendar/calendar-event"
import Footer from "examples/Footer"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import EventDetailCard from "../components/EventDetailCard"
import ForgeCta from "forge/core/components/ForgeCta"
import { EmailOutlined, PersonSearchOutlined } from "@mui/icons-material"
import MDTypography from "components/MDTypography"
import { CalendarTypeExtended } from "types/calendar/calendar"

function EventOverview() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  // Context
  const { events, getEvent } = useContext(EventsContext)
  const { getCalendar, isCalendarWritable } = useContext(CalendarsContext)

  // State
  const [event, setEvent] = useState<CalendarEvent | null>()
  const [selectedContact, setSelectedContact] = useState<any>(null)
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)

  const handleOpenDrawer = () => setOpenCreateEventDrawer(true)
  const handleCloseDrawer = () => setOpenCreateEventDrawer(false)

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  useEffect(() => {
    if (!id) return

    let calendarEvent = getEvent(id)
    setEvent(calendarEvent)

    // Set initial contact
    if (calendarEvent?.contacts.length > 0) {
      setSelectedContact(calendarEvent?.contacts[0])
    } else {
      setSelectedContact(undefined)
    }
  }, [id, events])

  useEffect(() => {
    // Used for when reloading the app
    // It's possible for the events to load faster than contacts, so if the user refreshes the page the event will come without a contact
    // This will ensure the contact is set correctly
    if (event?.contacts.length > 0) {
      setSelectedContact(event?.contacts[0])
    } else {
      setSelectedContact(undefined)
    }
  }, [event?.contacts.length])

  const clickContact = (contact: any) => {
    setSelectedContact(contact)
  }

  const getBackSteps = () => {
    let backSteps = searchParams.get("backSteps")
    if (backSteps && !isNaN(parseInt(backSteps))) {
      return parseInt(backSteps)
    }

    return -1
  }

  return (
    <DashboardLayout>
      <CreateEvent openDrawer={openCreateEventDrawer} handleCloseDrawer={handleCloseDrawer} event={event} />
      <MDBox mb={3}>
        <EventDetailCard event={event} handleOpenDrawer={handleOpenDrawer} backSteps={getBackSteps()} />
        <MDBox mt={2} />
        <MDBox display="flex">
          {event?.contacts?.length > 0 ? (
            event?.contacts.map((contact: any) => {
              let attendee

              if (event?.properties) {
                let attendeeIndex = event.properties.attendeesRefs?.map(ref => ref.id).indexOf(contact.ref?.id)

                if (attendeeIndex !== -1 && event.properties.attendees.length > attendeeIndex) {
                  attendee = event.properties.attendees[attendeeIndex]
                } else {
                  let attendeeIndex = event?.properties?.attendeesResearchRefs?.map(ref => ref.id).indexOf(contact.ref?.id)
                  if (attendeeIndex !== -1 && event.properties.attendeesResearch.length > attendeeIndex) {
                    attendee = event.properties.attendeesResearch[attendeeIndex]
                  }
                }
              }

              return (
                <div style={{ margin: "0rem 1rem 1rem 0rem", cursor: "pointer" }}>
                  <ForgeContactAvatar
                    contact={contact}
                    attendee={attendee}
                    diameter={50}
                    enableScoreBadge={false}
                    onContactTapped={() => clickContact(contact)}
                  />
                </div>
              )
            })
          ) : event?.isEventEditable(isCalendarWritable) ? (
            <Stack direction="column" spacing={4} style={{ width: "100%", marginTop: "24vh", marginBottom: "24vh" }}>
              <ForgeCta cta="Invite People by Email" icon={<EmailOutlined />} onClick={handleOpenDrawer} />
              <Divider style={{ opacity: 1 }} />
              <ForgeCta cta="Add People for research only" icon={<PersonSearchOutlined />} onClick={handleOpenDrawer} />
            </Stack>
          ) : (
            <Stack direction="column" spacing={4} style={{ width: "100%", marginTop: "32vh", marginBottom: "32vh" }}>
              <MDTypography variant="body2" fontWeight="medium" align="center" style={{ color: "darkgray" }}>
                Sorry, {CalendarTypeExtended.getName(getCalendar(event?.calendarId)?.type)} doesn't allow this event to be edited in Forge.
              </MDTypography>
            </Stack>
          )}
        </MDBox>
        {selectedContact && <ContactOverview contactId={selectedContact?.id} />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EventOverview
