import { createContext, useContext, useState, useEffect } from "react"
import { AuthContext } from "context"
import { CalendarType, ForgeCalendar } from "types/calendar/calendar"
import CalendarFirestoreService from "./firestore"

interface CalendarsContextType {
  calendars: ForgeCalendar[]
  getCalendar(calendarId: string): ForgeCalendar | null
  getCalendars(calendarIds: string[]): ForgeCalendar[]
  getCalendarsByType(calendarType: CalendarType): ForgeCalendar[]
  getWritableCalendars(): ForgeCalendar[]
  isCalendarWritable(calendarId: string): boolean
  updateCalendar(calendar: ForgeCalendar): Promise<void>
}

export const CalendarsContext = createContext<CalendarsContextType>({
  calendars: [],
  getCalendar: (calendarId: string): ForgeCalendar => null,
  getCalendars: (calendarIds: string[]): ForgeCalendar[] => [],
  getCalendarsByType: (calendarType: CalendarType): ForgeCalendar[] => [],
  getWritableCalendars: (): ForgeCalendar[] => [],
  isCalendarWritable: (calendarId: string): boolean => false,
  updateCalendar: (calendar: ForgeCalendar): Promise<void> => Promise.resolve()
})

export const CalendarsContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const calendarFirestoreService = new CalendarFirestoreService(user, userProfileData, encryptionService)

  // State
  const [calendars, setCalendars] = useState<ForgeCalendar[]>([])

  useEffect(() => {
    if (user) {
      const unsubscribe = calendarFirestoreService.getCalendars(async calendars => {
        setCalendars(calendars)
      })

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized])

  const getCalendar = (calendarId: string): ForgeCalendar => {
    return calendars.find(calendar => calendar.id === calendarId)
  }

  const getCalendars = (calendarIds: string[]): ForgeCalendar[] => {
    let selectedCalendars = []

    for (const calendarId of calendarIds) {
      selectedCalendars.push(calendars.find(calendar => calendar.id === calendarId))
    }

    return selectedCalendars.filter(e => e)
  }

  const getCalendarsByType = (calendarType: CalendarType): ForgeCalendar[] => {
    return calendars.filter(e => e.type === calendarType)
  }

  const getWritableCalendars = (): ForgeCalendar[] => {
    return calendars.filter(e => e.active && !e.isReadOnly && (e.version == 2 || e.type === CalendarType.local))
  }

  const isCalendarWritable = (calendarId: string): boolean => {
    return calendars.find(e => e.id === calendarId)?.canWeWriteToIt ?? false
  }

  const updateCalendar = async (calendar: ForgeCalendar): Promise<void> => {
    await calendarFirestoreService.updateCalendar(calendar)
    return
  }

  return (
    <CalendarsContext.Provider
      value={{
        calendars,
        getCalendar,
        getCalendars,
        getCalendarsByType,
        getWritableCalendars,
        isCalendarWritable,
        updateCalendar
      }}
    >
      {children}
    </CalendarsContext.Provider>
  )
}
