import { Card, Divider, IconButton, List, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { AddIcCall, ArrowForwardIos } from "@mui/icons-material"
import { useContext, useEffect, useState } from "react"
import { CallsContext } from "forge/pipeline/calls/services/CallsContext"
import { Call } from "types/pipeline/call"
import CallCard from "forge/pipeline/opportunities/components/CallCard"
import { smartTime, startOfDay } from "forge/core/utilities"
import { EventsContext } from "context"
import { ForgeEvent } from "types/forge-event"
import { CalendarEvent } from "types/calendar/calendar-event"
import EventCard from "forge/core/components/EventCard"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"

function ContactCallsCard({ contact, handleOpenDrawer }: { contact: any; handleOpenDrawer: () => void }) {
  // Context
  const { calls } = useContext(CallsContext)
  const { events, getFutureEventsForContact } = useContext(EventsContext)

  // State
  const [contactCalls, setContactCalls] = useState([])
  const [contactEvents, setContactEvents] = useState([])
  const [sections, setSections] = useState([])

  useEffect(() => {
    setContactCalls(calls)
  }, [calls])

  useEffect(() => {
    if (contact?.ref?.id) {
      setContactEvents(getFutureEventsForContact([contact.ref.id]))
    }
  }, [events, contact?.ref?.id])

  useEffect(() => {
    setSections(orderCalls([...contactCalls, ...contactEvents]))
  }, [contactCalls, contactEvents])

  function orderCalls(calls: ForgeEvent[]): any[] {
    const groupedCalls: { [key: string]: Call[] } = {}
    let currentDay: string | undefined

    for (const currentCall of calls) {
      const currentDayTmp: Date = startOfDay(currentCall instanceof Call ? currentCall.date : currentCall.startDate?.toDate() ?? new Date())
      if (currentDay !== currentDayTmp.toISOString()) {
        currentDay = currentDayTmp.toISOString()
      }

      const callGroup: any[] = groupedCalls[currentDay] ?? []
      callGroup.push(currentCall)
      groupedCalls[currentDay] = callGroup
    }

    // Used for Ascending Chronological Order for same day Calls
    for (const key of Object.keys(groupedCalls)) {
      groupedCalls[key] = groupedCalls[key].reverse()
    }

    let sections = []
    for (const [key, value] of Object.entries(groupedCalls)) {
      let keyDate = new Date(key)
      sections.push({
        keyDate,
        items: value,
        header:
          Math.abs(keyDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24) < 29
            ? smartTime(keyDate)
            : new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric" }).format(keyDate)
      })
    }

    return sections.sort((a, b) => a.keyDate.getTime() - b.keyDate.getTime())
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Conversations" tooltipInfo={CARDS_TOOLTIPS.CONVERSATIONS} />
        <MDBox display="flex" alignItems="center" sx={{ justifyContent: "space-between" }}>
          {calls.length > 0 && (
            <MDBox
              width="3rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              style={{ cursor: "pointer", background: "cornflowerblue" }}
              onClick={handleOpenDrawer}
            >
              <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
                <AddIcCall style={{ color: "white" }} />
              </IconButton>
            </MDBox>
          )}
        </MDBox>
        <Divider style={{ opacity: 1 }} />
        {sections && sections.length > 0 ? (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper"
            }}
          >
            {sections.map(e => (
              <MDBox>
                <MDTypography fontSize="small" align="center" style={{ color: "gray", marginTop: 1.5, marginBottom: 8 }}>
                  {e.header}
                </MDTypography>
                {e.items.map((event: any) => {
                  if (event instanceof Call) {
                    return <CallCard call={event} />
                  } else if (event instanceof CalendarEvent) {
                    return <EventCard event={event} />
                  }
                })}
              </MDBox>
            ))}
          </List>
        ) : (
          <Stack mt={5} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
            <MDTypography
              variant="body2"
              align="center"
              style={{ color: "darkgray" }}
            >{`Add any conversation\nyou've had with ${contact?.firstName}?`}</MDTypography>
            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
            <MDBox
              width="3rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              style={{ cursor: "pointer", background: "cornflowerblue" }}
              onClick={handleOpenDrawer}
            >
              <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
                <AddIcCall style={{ color: "white" }} />
              </IconButton>
            </MDBox>
          </Stack>
        )}
      </MDBox>
    </Card>
  )
}

export default ContactCallsCard
