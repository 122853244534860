// @mui material components
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"

// Settings page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import SettingsSidenav from "./components/SettingsSidenav"
import SettingsHeader from "./components/SettingsHeader"
import SettingsBasicInfo from "./components/SettingsBasicInfo"
import SettingsCalendars from "./components/SettingsCalendars"
import SettingsCrm from "./components/SettingsCrm"
import SettingsDeleteAccount from "./components/SettingsDeleteAccount"
import SettingsCrossDeviceAccessKey from "./components/SettingsCrossDeviceAccessKey"
import SettingsNotifications from "./components/SettingsNotifications"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { CrmContextProvider } from "./services/CrmContext"
import { SettingsContextProvider } from "./services/SettingsContext"
import SettingsBilling from "./components/SettingsBilling"

function Settings(): JSX.Element {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <SettingsContextProvider>
        <DashboardLayout>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2.5}>
              <SettingsSidenav />
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SettingsHeader />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsBasicInfo />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsCrossDeviceAccessKey />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsNotifications />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsBilling section={"settings"} />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsCrm />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsCalendars />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsDeleteAccount />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </DashboardLayout>
      </SettingsContextProvider>
    </GoogleOAuthProvider>
  )
}

export default Settings
