import { Switch } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { useEffect, useState } from "react"

function NotificationTile({
  text,
  isEnabled = false,
  onEnabled
}: {
  text: string
  isEnabled?: boolean
  onEnabled?: (enabled: boolean) => void
}): JSX.Element {
  // State
  const [enabled, setEnabled] = useState<boolean>(false)

  useEffect(() => {
    setEnabled(isEnabled)
  }, [isEnabled])

  return (
    <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection="row">
      <MDTypography variant="button" color="text">
        {text}
      </MDTypography>
      <MDBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
        <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
          <Switch checked={enabled} onChange={(_, checked) => onEnabled(checked)} />
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default NotificationTile
