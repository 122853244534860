// Images
import forgeLogo from "assets/forge/images/forge.png"
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"

export const SplashScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={forgeLogo} alt="Image 1" style={{ margin: "10px", width: "25%" }} />
        <img src={spinningSphere} alt="Image 2" style={{ margin: "10px", width: "25%" }} />
      </div>
    </div>
  )
}
