import PropTypes from "prop-types"
import { useState, useEffect, useContext } from "react"
import theme from "assets/theme"

// MUI
import { Card, Grid, useMediaQuery, Divider, Stack } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Components
import MilestoneCard from "../../../milestones/components/MilestoneCard"
import Calendar from "examples/Calendar"
import { MilestonesContext } from "forge/milestones/services/MilestonesContext"

import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"
import { InfoOutlined } from "@mui/icons-material"
import AddKnowledgeDrawer from "forge/knowledge/components/AddKnowledgeDrawer"

function MilestonesView({}: {}) {
  // Context
  const { milestones, loading } = useContext(MilestonesContext)

  // State
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [schedulerData, setSchedulerData] = useState([])
  const [agendaData, setAgendaData] = useState({})
  const [openCreateMilestoneDrawer, setOpenCreateMilestoneDrawer] = useState(false)

  const handleOpenDrawer = () => setOpenCreateMilestoneDrawer(true)
  const handleCloseDrawer = () => setOpenCreateMilestoneDrawer(false)

  useEffect(() => {
    let parsedMilestones = parseMilestones(milestones)
    setSchedulerData(parsedMilestones)
    setAgendaData(getAgendaMilestones(parsedMilestones))
  }, [milestones])

  useEffect(() => {
    const endDate = new Date(selectedDay)
    endDate.setDate(endDate.getDate() + 35)
    setEndDate(endDate)
  }, [selectedDay])

  const parseMilestones = (info: any[]) => {
    let updatedInfo = info.map(milestone => {
      return {
        id: milestone.id,
        title: milestone.name,
        start: milestone.startDate?.toDate(),
        end: milestone.endDate?.toDate() ?? milestone.startDate?.toDate(),
        className: "info",
        ...milestone
      }
    })

    return updatedInfo
  }

  const getAgendaMilestones = (info: any[]) => {
    const endDate = new Date(selectedDay)
    endDate.setDate(endDate.getDate() + 35)

    const filteredMilestones = info.filter(milestone => {
      const eventDate = new Date(milestone.start)
      return eventDate >= selectedDay && eventDate <= endDate
    })

    return groupMilestonesByDay(filteredMilestones)
  }

  // Function to group events by day
  function groupMilestonesByDay(milestones: any[]) {
    const groupedMilestones: { [key: string]: any } = {}
    milestones.forEach(milestone => {
      const startDate = new Date(milestone.start)
      const dateKey = startDate.toISOString().split("T")[0] // Using date as key
      if (!groupedMilestones[dateKey]) {
        groupedMilestones[dateKey] = []
      }
      groupedMilestones[dateKey].push(milestone)
    })
    return groupedMilestones
  }

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const handleDateChange = (date: any) => {
    setSelectedDay(date.start)
    setAgendaData(getAgendaMilestones(schedulerData))
  }

  return (
    <Card>
      <AddKnowledgeDrawer openDrawer={openCreateMilestoneDrawer} handleCloseDrawer={handleCloseDrawer} />
      <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium">
          Milestones
        </MDTypography>
        {/* {ability.can("create", "categories") && ( */}
        <MDButton variant="gradient" color="dark" size="small" type="submit" style={{ textTransform: "none" }} onClick={handleOpenDrawer}>
          + Add Milestone
        </MDButton>
        {/* )} */}
      </MDBox>
      <MDBox py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox ml={3}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Next 7 days
                </MDTypography>
              </MDBox>
              {loading && (
                <img
                  src={spinningSphere}
                  alt="Image 2"
                  style={{ width: "40%", display: "block", marginTop: 64, marginLeft: "auto", marginRight: "auto" }}
                />
              )}
              {!loading && Object.entries(agendaData).length === 0 && (
                <Stack mt={5} mb={3} direction="column" alignItems="center" spacing={0.5}>
                  <InfoOutlined style={{ color: "darkgray" }} />
                  <MDTypography variant="body1" style={{ color: "darkgray" }}>
                    No milestones found
                  </MDTypography>
                </Stack>
              )}
              {Object.entries(agendaData).map((day: any) => (
                <MDBox px={2}>
                  <MDBox mb={2}>
                    <MDTypography variant="caption" fontWeight="bold" fontSize="medium" textTransform="capitalize" style={{ color: "#0000008f" }}>
                      {new Date(day[0]).toLocaleDateString("en-US", {
                        weekday: "long",
                        month: "short",
                        day: "2-digit",
                        timeZone: "UTC"
                      })}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: "none" }}>
                    {day[1].map((milestone: any) => (
                      <MilestoneCard milestone={milestone} />
                    ))}
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          </Grid>
          <Divider orientation="vertical" flexItem style={{ height: "auto" }} />
          <Grid item xs={11} md={7} lg={7}>
            <MDBox mr={3}>
              <Calendar
                header={{
                  date: selectedDay.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                    timeZone: "UTC"
                  })
                }}
                headerToolbar={false}
                initialView="dayGridMonth"
                initialDate={selectedDay}
                events={schedulerData.map(event => {
                  return {
                    id: event.id,
                    title: event.title,
                    start: event.start.toISOString().split("T")[0],
                    end: event.end.toISOString().split("T")[0],
                    className: "info"
                  }
                })}
                selectable
                editable
                select={handleDateChange}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of MilestoneCard
MilestonesView.defaultProps = {
  milestones: null
}

// Typechecking props for the MilestoneCard
MilestonesView.propTypes = {
  milestones: PropTypes.array.isRequired
}

export default MilestonesView
