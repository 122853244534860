import { Timestamp } from "firebase/firestore"
import { Crm, crmStringMap } from "types/pipeline/crm"

export class UserPipelineConfig {
  isEnabled: boolean
  connectionId?: string | null
  integration?: Crm | null
  organizationId?: string | null
  enableDate?: Date | null

  constructor({
    isEnabled = false,
    connectionId = null,
    integration = null,
    organizationId = null,
    enableDate = null
  }: {
    isEnabled?: boolean
    connectionId?: string | null
    integration?: Crm | null
    organizationId?: string | null
    enableDate?: Date | null
  } = {}) {
    this.isEnabled = isEnabled
    this.connectionId = connectionId
    this.integration = integration
    this.organizationId = organizationId
    this.enableDate = enableDate
  }

  copyWith({
    isEnabled,
    connectionId,
    integration,
    organizationId,
    enableDate
  }: {
    isEnabled?: boolean
    connectionId?: string | null
    integration?: Crm | null
    organizationId?: string | null
    enableDate?: Date | null
  }): UserPipelineConfig {
    return new UserPipelineConfig({
      isEnabled: isEnabled ?? this.isEnabled,
      connectionId: connectionId ?? this.connectionId,
      integration: integration ?? this.integration,
      organizationId: organizationId ?? this.organizationId,
      enableDate: enableDate ?? this.enableDate
    })
  }

  toMap({ toFirestore = true }: { toFirestore?: boolean } = {}): {
    [key: string]: any
  } {
    return {
      isEnabled: this.isEnabled,
      connectionId: this.connectionId,
      integration: this.integration,
      organizationId: this.organizationId,
      enableDate: this.enableDate !== null ? (toFirestore ? Timestamp.fromDate(this.enableDate) : this.enableDate.getTime()) : null
    }
  }

  static fromMap(map: { [key: string]: any }): UserPipelineConfig {
    return new UserPipelineConfig({
      isEnabled: map?.isEnabled ?? false,
      connectionId: map?.connectionId,
      integration: Object.keys(crmStringMap).find(key => crmStringMap[key as Crm] === map?.integration) as Crm | undefined,
      organizationId: map?.organizationId,
      enableDate:
        map?.enableDate !== null
          ? map?.enableDate instanceof Timestamp
            ? (map?.enableDate as Timestamp).toDate()
            : new Date(map?.enableDate)
          : new Date()
    })
  }
}
