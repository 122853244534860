import { Box, DialogContent, DialogContentText } from "@mui/material"
import video from "assets/forge/videos/relationship4.mp4"

function Relationship4Slide({}: {}) {
  return (
    <DialogContent>
      <Box
        style={{
          textAlign: "center"
        }}
      >
        <DialogContentText align="center" display="inline">
          {"Find"}
        </DialogContentText>
        <DialogContentText color="#3D79EE" fontWeight="bold" align="center" display="inline">
          {" common interests "}
        </DialogContentText>
        <DialogContentText align="center" display="inline">
          {"to develop rapport"}
        </DialogContentText>
      </Box>
      <video
        autoPlay
        muted
        loop
        style={{
          height: "356px",
          aspectRatio: 16 / 9,
          width: "50%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
    </DialogContent>
  )
}

export default Relationship4Slide
