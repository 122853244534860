// @material-ui core components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { AuthContext } from "context"
import { SettingsContext } from "forge/settings/services/SettingsContext"

// Settings page components
import FormField from "layouts/pages/account/components/FormField"
import { useContext, useEffect, useState } from "react"
import { capitalize, debounce } from "lodash"
import { Avatar, Dialog, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material"
import { Add, Remove, Verified } from "@mui/icons-material"
import { UserEmail } from "types/user/user-email"
import SignInButton from "forge/auth/components/SignInButton"
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"

function SettingsBasicInfo(): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { userProfileData } = getCurrentUser()
  const {
    updateBasicInfo,
    verifyGoogleEmail,
    verifyMicrosoftEmail,
    emailToVerify,
    setEmailToVerify,
    verifyingEmails,
    setVerifyingEmails,
    errorVerifyingEmails,
    setErrorVerifyingEmails
  } = useContext(SettingsContext)

  // State
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [preferredName, setPreferredName] = useState<string>()
  const [company, setCompany] = useState<string>()
  const [linkedInUrl, setLinkedInUrl] = useState<string>()
  const [emailValues, setEmailValues] = useState<UserEmail[]>([])
  const [updatedFromTextField, setUpdatedFromTextField] = useState<boolean>(false)

  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setFirstName(userProfileData?.firstName)
    setLastName(userProfileData?.lastName)
    setPreferredName(userProfileData?.preferredName)
    setCompany(userProfileData?.company)
    setLinkedInUrl(userProfileData?.linkedInUrl)
    if (userProfileData?.emails && userProfileData.emails!.length > 0) {
      setEmailValues(userProfileData.emails)
    }
  }, [userProfileData])

  useEffect(() => {
    if (updatedFromTextField) {
      // Debounce the updateBasicInfo function
      const debouncedFunction = debounce(async () => {
        setUpdatedFromTextField(false)
        await updateBasicInfo(firstName, lastName, preferredName, company, linkedInUrl, emailValues)
      }, 500)

      debouncedFunction()

      return () => {
        // Cleanup function
        debouncedFunction.cancel() // Cancel debounce on component unmount
      }
    }
  }, [firstName, lastName, preferredName, company, linkedInUrl, emailValues])

  // useEffect(() => {
  //     console.log("useEffect", emailToVerify?.verified, verifyEmailDialogOpen);
  //     if (emailToVerify?.verified && verifyEmailDialogOpen) {
  //         setVerifyEmailDialogOpen(false);
  //     }
  // }, [emailToVerify]);

  const onEmailEdited = (value: string, index: number) => {
    // Clone the original array
    const updatedValues = [...emailValues]
    // Update the value property of the item at the specified index
    updatedValues[index].email = value
    setEmailValues(updatedValues)
    setUpdatedFromTextField(true)
  }

  const onEmailAdded = () => {
    setEmailValues([
      ...emailValues,
      new UserEmail({
        email: "",
        source: "manual",
        verified: false
      })
    ])
  }

  const onEmailRemoved = (index: number) => {
    setEmailValues(prevEmails => {
      // Create a new array with the item removed
      const updatedItems = [...prevEmails.slice(0, index), ...prevEmails.slice(index + 1)]
      return updatedItems
    })
    setUpdatedFromTextField(true)
  }

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Your Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              placeholder="John"
              value={firstName}
              onChange={(e: any) => {
                setFirstName(e.target.value)
                setUpdatedFromTextField(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Last Name"
              placeholder="Doe"
              value={lastName}
              onChange={(e: any) => {
                setLastName(e.target.value)
                setUpdatedFromTextField(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Preferred Name"
              placeholder="Johnny"
              value={preferredName}
              onChange={(e: any) => {
                setPreferredName(e.target.value)
                setUpdatedFromTextField(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Organization"
              placeholder="Forge"
              value={company}
              onChange={(e: any) => {
                setCompany(e.target.value)
                setUpdatedFromTextField(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="LinkedIn Profile"
              placeholder="https://www.linkedin.com/in/..."
              value={linkedInUrl}
              onChange={(e: any) => {
                setLinkedInUrl(e.target.value)
                setUpdatedFromTextField(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography pl={0.5} variant="body2" fontSize="small" fontWeight="regular" style={{ color: "black" }}>
              Email
            </MDTypography>
            <Dialog
              open={verifyEmailDialogOpen}
              onClose={() => {
                setErrorVerifyingEmails(undefined)
                return setVerifyEmailDialogOpen(false)
              }}
              PaperProps={{
                style: {
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  maxWidth: "400px"
                },
                sx: { borderRadius: "16px" }
              }}
            >
              <DialogTitle style={{ alignSelf: "center" }}>Verify {emailToVerify?.email}</DialogTitle>
              {errorVerifyingEmails && (
                <MDTypography variant="body2" style={{ color: "red", textAlign: "center" }}>
                  {errorVerifyingEmails}
                </MDTypography>
              )}
              <DialogContent style={{ alignSelf: "center" }}>
                {verifyingEmails ? (
                  <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
                ) : (
                  <>
                    <SignInButton
                      ssoOption="google"
                      cta="Verify"
                      onClick={() => {
                        setVerifyingEmails(true)
                        return verifyGoogleEmail()
                      }}
                    />
                    <MDBox height={"8px"} />
                    <SignInButton
                      ssoOption="microsoft"
                      cta="Verify"
                      onClick={() =>
                        verifyMicrosoftEmail(() => {
                          setVerifyEmailDialogOpen(false)
                        })
                      }
                    />
                  </>
                )}
              </DialogContent>
            </Dialog>
            {emailValues.map((email, index) => (
              <Stack direction="row" spacing={1} alignItems="center" style={{ marginTop: 8 }}>
                <Avatar sx={{ width: 20, height: 20 }} style={{ background: "black", cursor: "pointer" }} onClick={() => onEmailRemoved(index)}>
                  <Remove />
                </Avatar>
                <FormField
                  label={null}
                  placeholder="example@email.com"
                  inputProps={{ type: "email" }}
                  value={email.email}
                  onChange={(e: any) => onEmailEdited(e.target.value, index)}
                />
                <Tooltip
                  title={email.verified ? `You verified this email through ${capitalize(email.source)}.` : "Verify this email by clicking me."}
                >
                  <Verified
                    style={{
                      color: email.verified ? "green" : "lightgray",
                      cursor: email.verified ? "auto" : "pointer"
                    }}
                    onClick={() => {
                      if (!email.verified) {
                        setEmailToVerify(email)
                        setVerifyEmailDialogOpen(true)
                      }
                    }}
                  />
                </Tooltip>
              </Stack>
            ))}

            <Stack direction="row" spacing={1} alignItems="center" style={{ marginTop: 8, cursor: "pointer" }} onClick={onEmailAdded}>
              <Avatar sx={{ width: 20, height: 20 }} style={{ background: "cornflowerblue" }}>
                <Add />
              </Avatar>
              <MDTypography pl={0.5} variant="body2" fontWeight="regular">
                Add a new email address
              </MDTypography>
            </Stack>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default SettingsBasicInfo
